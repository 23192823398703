import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import IconSvg from "../../../../App/AppMenu/IconSvg";
import Button from "react-bootstrap/Button";
import Comment from "./Comment";
import { getCandidateById, upsertComment } from "../Store/Thunk";
import Form from "react-bootstrap/Form";
import { SetSelectedComment } from "../Store";
import AdminBreadCrumb from "../../../../Components/Dashboard/AdminBreadCrumb";

const Candidate = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.candidate.isLoading);
  const candidate = useSelector((state) => state.candidate.candidate);
  const selectedComment = useSelector((state) => state.candidate.selectedComment);
  const companyUserId = useSelector((state) => state.adminData.companyUser.companyUserId);
  const { state } = useLocation();
  const [inputType, setInputType] = useState("selector");
  const [commentInputText, setCommentInputText] = useState("");

  useEffect(() => {
    dispatch(getCandidateById({ id: state.candidateId }));
    setCommentInputText("");
    dispatch(SetSelectedComment(null));
  }, [state.candidateId]);

  useEffect(() => {
    if (selectedComment) {
      setCommentInputText(selectedComment.content);
    }
  }, [selectedComment]);

  const inputBox = (inputBoxType) => {
    if (selectedComment || inputBoxType === "createComment") {
      return (
        <>
          <Form.Control
            as={"textarea"}
            className={"h-100"}
            value={commentInputText}
            onChange={(e) => {
              setCommentInputText(e.target.value);
            }}
          ></Form.Control>
          <div
            className={"absolute-top-right corner show-cursor"}
            onClick={() => {
              setInputType("selector");
              setCommentInputText("");
              dispatch(SetSelectedComment(null));
            }}
          >
            <IconSvg icon={"back"}></IconSvg>
          </div>

          <Button type={"submit"} className={"absolute-bottom-right corner"}>
            {selectedComment ? "güncelle" : "ekle"}
          </Button>
        </>
      );
    } else {
      return (
        <Row>
          <Col xs={"auto"}>
            <Button
              onClick={() => {
                setInputType("createComment");
              }}
            >
              YORUM EKLE
            </Button>
          </Col>
          <Col xs={"auto"}>
            <Button
              onClick={() => {
                console.log("you should update a cv here");
                debugger;
              }}
            >
              CV EKLE
            </Button>
          </Col>
        </Row>
      );
    }
  };

  const HandleSubmit = (e) => {
    e.preventDefault();
    if (selectedComment) {
      dispatch(
        upsertComment({
          content: commentInputText,
          commentId: selectedComment.commentId,
        })
      );
    } else {
      dispatch(
        upsertComment({
          content: commentInputText,
          companyUserId: companyUserId,
          candidateId: candidate.candidateId,
        })
      );
    }
  };

  return (
    <div>
      <Row className={"mx-0"}>
        <Col>
          <div className={"text-14 text-color-gray-light fw-medium mb-3"}>ADAY</div>

          <AdminBreadCrumb
            pageName={t("candidateBreadCrumbLabel")}
            backPageName={t("createCandidateBreadCrumbLabel")}
            backUrl="/createCandidate"
          >
            <Row className={"h-100"}>
              <Col>
                <Card className={"breadcrumb-card h-100"}>
                  <Row className={"h-100 mx-0"}>
                    <Col className={"align-content-center text-20 text-color-gray-dark fw-medium"}>
                      {candidate.name}
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col xs={"auto"}>
                <Button
                  className={"button secondary"}
                  size="sm"
                  style={{ height: "46px", width: "46px", padding: "4px", borderRadius: "10px" }}
                >
                  <IconSvg icon="edit" fill={"#0077B6"} />
                </Button>
              </Col>
            </Row>
          </AdminBreadCrumb>
        </Col>
      </Row>

      <Row className={"mx-0"}>
        <Col>
          <Card className={"default-card"} style={{ height: "165px" }}>
            <Col className={"align-content-center"}>
              <Row>
                <Col xs={"auto"} className="align-content-center ms-5">
                  <Row className={"justify-content-center"}>
                    <div
                      style={{
                        border: "1px solid #E7ECEF",
                        width: "80px",
                        height: "80px",
                        borderRadius: "50%",
                        alignContent: "center",
                      }}
                    >
                      <Row className={"justify-content-center"}>
                        <IconSvg icon={"candidate"} />
                      </Row>
                    </div>
                  </Row>
                </Col>
                <Col xs={"auto"} className={"ms-5 fw-medium"}>
                  <Row>
                    <Col xs={"auto"} className={"px-0 align-content-center text-16 text-color-gray-light"}>
                      Aday Adı Soyadı:
                    </Col>
                    <Col xs={"auto"} className={"px-1 align-content-center text-18 text-color-gray-dark  "}>
                      {candidate.name}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={"auto"} className={"px-0 align-content-center text-16 text-color-gray-light"}>
                      E-mail:
                    </Col>
                    <Col xs={"auto"} className={"px-1 align-content-center text-18 text-color-gray-dark "}>
                      {candidate.email}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={"auto"} className={"px-0 align-content-center text-16 text-color-gray-light"}>
                      Telefon:
                    </Col>
                    <Col xs={"auto"} className={"px-1 align-content-center text-18 text-color-gray-dark "}>
                      {candidate.phoneNumber}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={"auto"} className={"px-0 align-content-center text-16 text-color-gray-light"}>
                      Pozisyon:
                    </Col>
                    <Col xs={"auto"} className={"px-1 align-content-center text-18 text-color-gray-dark"}>
                      {candidate.positionName}
                    </Col>
                  </Row>

                  {/*


                <Row>e-mail:{candidate.email}</Row>
                <Row>telefon: {candidate.phoneNumber}</Row>
                <Row>pozisyon: {candidate.positionName}</Row>


                */}
                </Col>
                <Col xs={4} className={"border-end border-start ms-5"} style={{ borderColor: "#E7ECEF" }}>
                  <Form onSubmit={HandleSubmit} className={"h-100 position-relative"}>
                    {inputBox(inputType)}
                  </Form>
                </Col>
                <Col xs={"auto"} className={"ms-auto align-content-center"}>
                  <Button className={"button secondary"}>Değerleme gönder</Button>
                </Col>
              </Row>
            </Col>
          </Card>
        </Col>
      </Row>
      <Row className={"mx-0"}>
        <Col>
          <Comment candidate={candidate}></Comment>
        </Col>
      </Row>

      <Row className={"mx-0"}>
        <Col>değerlemeler</Col>
      </Row>
    </div>
  );
};
export default Candidate;
