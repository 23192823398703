export const CreateCandidateApiUrls = {
  createCandidate: "candidate/CreateCandidate",
  updateCandidate: "candidate/UpdateCandidate",
  deleteCandidate: "candidate/DeleteCandidate",
  getCandidatesByCompanyId: "candidate/GetCandidatesByCompanyId",
  getCandidateById: "candidate/GetCandidateById",
};

export default class createCandidateAPI {
  constructor(httpsClient, cache) {
    this.httpsClient = httpsClient;
    this.cache = cache;
  }

  async createCandidate(data, token) {
    return this.post(CreateCandidateApiUrls.createCandidate, data, token);
  }

  async updateCandidate(data, token) {
    return this.post(CreateCandidateApiUrls.updateCandidate, data, token);
  }

  async deleteCandidate(data, token) {
    return this.get(CreateCandidateApiUrls.deleteCandidate, data, token);
  }

  async getCandidatesByCompanyId(data, token) {
    return this.get(CreateCandidateApiUrls.getCandidatesByCompanyId, data, token);
  }

  async getCandidateById(data, token) {
    return this.get(CreateCandidateApiUrls.getCandidateById, data, token);
  }

  async get(url, data, token, eAlert, sAlert) {
    return await this.httpsClient.get(url, data, token, eAlert, sAlert);
  }

  async post(url, data, token, eAlert, sAlert) {
    this.cache.remove("tests");

    return await this.httpsClient.post(url, data, token, eAlert, sAlert);
  }
}
