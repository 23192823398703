import { createAsyncThunk } from "@reduxjs/toolkit";
import CreditsAPI, { api } from "../API/CreditsAPI";

import LocalStorage from "../../../../Services/Storage/LocalStorage";
import StorageModel from "../../../../Models/StorageModel";
import HttpsClient from "../../../../Services/HttpsClient";
import ApiHelper from "../../../../Services/ApiHelper";
import Cache from "../../../../Services/Cache";

const storage = new LocalStorage();
const storageModel = new StorageModel(storage);
const cache = new Cache(storageModel);

const httpsClient = new HttpsClient();
const apiHelper = new ApiHelper(httpsClient);
const creditsAPI = new CreditsAPI(apiHelper, cache);

export const getCreditsByCompanyId = createAsyncThunk(
  api.getCreditsByCompanyId,
  async (payload, { getState, dispatch }) => {
    const state = getState();
    const token = state.auth.user.token;
    if (!payload) {
      payload = { companyId: state.credits.companyId };
    }
    return await creditsAPI.getCreditsByCompanyId(payload, token);
  }
);

export const upsertCredit = createAsyncThunk(api.upsertCredit, async (payload, { getState, dispatch }) => {
  const state = getState();
  const token = state.auth.user.token;

  const response = await creditsAPI.upsertCredit(payload, token);

  dispatch(getCreditsByCompanyId());

  return response;
});

export const getUploadRecordsByCreditId = createAsyncThunk(
  api.getUploadRecordsByCreditId,
  async (payload, { getState, dispatch }) => {
    const state = getState();
    const token = state.auth.user.token;

    const response = await creditsAPI.getUploadRecordsByCreditId(payload, token);

    dispatch(getCreditsByCompanyId());

    return response;
  }
);

export const getB2bAssessments = createAsyncThunk(api.getB2bAssessments, async (payload, { getState, dispatch }) => {
  const state = getState();
  const token = state.auth.user.token;

  const response = await creditsAPI.getB2bAssessments(payload, token);

  dispatch(getCreditsByCompanyId());

  return response;
});
