export const CandidateApiUrls = {
  getCandidateById: "candidate/GetCandidateById",
  getCommentsByCandidateId: "comment/getCommentsByCandidateId",
  upsertComment: "comment/UpsertComment",
  deleteComment: "comment/deleteComment",
};

export default class candidateAPI {
  constructor(httpsClient, cache) {
    this.httpsClient = httpsClient;
    this.cache = cache;
  }

  async getCandidateById(data, token) {
    return this.get(CandidateApiUrls.getCandidateById, data, token);
  }

  async getCommentsByCandidateId(data, token) {
    return this.get(CandidateApiUrls.getCommentsByCandidateId, data, token);
  }

  async upsertComment(data, token) {
    return this.post(CandidateApiUrls.upsertComment, data, token);
  }

  async deleteComment(data, token) {
    return this.get(CandidateApiUrls.deleteComment, data, token);
  }

  async get(url, data, token, eAlert, sAlert) {
    return await this.httpsClient.get(url, data, token, eAlert, sAlert);
  }

  async post(url, data, token, eAlert, sAlert) {
    this.cache.remove("tests");

    return await this.httpsClient.post(url, data, token, eAlert, sAlert);
  }
}
