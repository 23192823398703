import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getCompanyCredits } from "../../Store/Thunk";
import Col from "react-bootstrap/Col";
import { Carousel } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import ApplicationCard from "./ApplicationCard";
import ApplicationCardContent from "./ApplicationCardContent";
import IconSvg from "../../../../../App/AppMenu/IconSvg";

const ApplicationCardList = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.adminData.companyUser.company.companyId);
  const companyCredits = useSelector((state) => state.adminDash.companyCredits);
  const [carouselItems, setCarouselItems] = useState([]);
  const [carouselKey, setCarouselKey] = useState("unset");

  useEffect(() => {
    updateColumns();
    window.addEventListener("resize", updateColumns);

    // Only dispatch the API call once
    if (companyCredits === undefined) {
      dispatch(getCompanyCredits({ companyId }));
    }

    return () => window.removeEventListener("resize", updateColumns);
  }, [companyCredits, companyId, dispatch]);

  const updateColumns = () => {
    const width = window.innerWidth;

    if (companyCredits && companyCredits.length > 0) {
      //We set the keys in order to unmount and remount the component. Causing it to go back to slide 1.
      if (width >= 2000) {
        if (carouselKey !== "xxxl") setCarouselKey("xxxl");
        setCarouselItems(groupIntoBundles(companyCredits, 6)); // For xxxl
      } else if (width >= 1400) {
        if (carouselKey !== "xxl") setCarouselKey("xxl");
        setCarouselItems(groupIntoBundles(companyCredits, 4)); // For xxl
      } else if (width >= 1200) {
        if (carouselKey !== "xl") setCarouselKey("xl");
        setCarouselItems(groupIntoBundles(companyCredits, 3)); // For xl
      } else if (width >= 992) {
        if (carouselKey !== "lg") setCarouselKey("lg");
        setCarouselItems(groupIntoBundles(companyCredits, 3)); // For lg
      } else if (width >= 768) {
        if (carouselKey !== "md") setCarouselKey("md");
        setCarouselItems(groupIntoBundles(companyCredits, 2)); // For md
      } else if (width >= 576) {
        if (carouselKey !== "sm") setCarouselKey("sm");
        setCarouselItems(groupIntoBundles(companyCredits, 2)); // For sm
      } else {
        if (carouselKey !== "xs") setCarouselKey("xs");
        setCarouselItems(groupIntoBundles(companyCredits, 1)); // For xs
      }
    }
  };

  const groupIntoBundles = (items, bundleSize) => {
    const result = [];
    let bundle = [];
    items.forEach((item, index) => {
      bundle.push(item);
      if ((index + 1) % bundleSize === 0 || index === items.length - 1) {
        result.push(bundle);
        bundle = [];
      }
    });
    return result;
  };

  return (
    <Carousel
      key={carouselKey}
      nextIcon={<IconSvg height={15} width={8} icon={"carousel-next"} />}
      prevIcon={<IconSvg height={15} width={8} icon={"carousel-prev"} />}
      className={"custom-carousel"}
    >
      {carouselItems &&
        carouselItems.map((carouselItem, index) => {
          return (
            <Carousel.Item className={"p-4"} key={index}>
              <Row className={"m-0 justify-content-between"}>
                {carouselItem.map((companyCredit, index) => {
                  return (
                    <Col xs={12} sm={6} md={6} lg={4} xxl={3} xxxl={2} key={index}>
                      <ApplicationCard>
                        <div className={"absolute-top-right"}>
                          <IconSvg icon={"infoButton"} />
                        </div>
                        <ApplicationCardContent companyCredit={companyCredit}></ApplicationCardContent>
                      </ApplicationCard>
                    </Col>
                  );
                })}
              </Row>
            </Carousel.Item>
          );
        })}
    </Carousel>
  );
};
export default ApplicationCardList;
