import React from "react";
import UseAuthHelper from "../../Helpers/Auth/UseAuthHelper";
import IconSvg from "./IconSvg";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

export default function TopNavigation(props) {
  const { t } = useTranslation();
  const { setAuth } = UseAuthHelper();

  let HandleLogOut = () => {
    localStorage.clear();
    setAuth(null);
    window.location.href = window.location.origin;
  };
  let handleLanguageChange = (lang) => {
    i18next.changeLanguage(lang);
  };

  let size = window.innerWidth <= 500 ? "40" : "40";
  let dropdownIconSize = window.innerWidth <= 500 ? "20" : "30";

  return (
    <Row className={"justify-content-between h-100"}>
      <Col xs={2} className={"align-content-center"}>
        <Row className={"justify-content-center"}>
          <Col xs={"auto"} className={"align-content-center"}>
            {/*<IconSvg icon="cp-logo" height={size} width={size} />*/}
            <IconSvg icon="cp-logo" height={size} width={size} fill={"#ffffff"} />
          </Col>
          <Col xs={"auto"} className={"text-32 text-color-white fw-medium"}>
            Cus - Pro
          </Col>
        </Row>
      </Col>
      <Col xs={7} className={"align-content-center"}>
        <div>
          <Row className={"text-32 fw-medium text-color-white"}>{t(props.pageName)}</Row>
          <Row className={"text-16 fw-medium text-color-gray-light"}>{t(props.pageName)}</Row>{" "}
          {/*TODO: What do we write here ?*/}
        </div>
      </Col>
      <Col></Col>
    </Row>
  );
}
