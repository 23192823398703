import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { deleteCompanyInfo, deleteImageFromCompanyInfo, getCompanyInfo } from "../Store/Thunk";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Col, Form, Row } from "react-bootstrap";
import IconSvg from "../../../../App/AppMenu/IconSvg";
import ApiHelper from "../../../../Helpers/ApiHelper";
import { ChangeCompanyInfoState, ClearCompanyInfoState } from "../Store";
import { CompanyInfoApiUrls } from "../API/CompanyInfoAPI";
import Overlay from "../../../../SharedComponents/Overlay";
import BreadCrumb from "../../../../Components/Dashboard/BreadCrumb";
import Card from "react-bootstrap/Card";
import draftToHtml from "draftjs-to-html";
import { convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { GetValueByKey, imageTypeEnum } from "../../../../Helpers/EnumHelper";

export default function CompanyInfo() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.companyInfo.isLoading);
  const companyInfo = useSelector((state) => state.companyInfo);
  const token = useSelector((state) => state.auth.user.token);
  const { state } = useLocation();
  const companyId = state?.id;
  const companyName = state?.name;
  const isUpdateOperation = useSelector((state) => state.companyInfo.isUpdateOperation);

  const [logo, setLogo] = useState(null);
  const [previewLogo, setPreviewLogo] = useState("");

  const [icon, setIcon] = useState(null);
  const [previewIcon, setPreviewIcon] = useState("");

  const [editorState, setEditorState] = useState("");

  useEffect(() => {
    if (companyId) {
      dispatch(ClearCompanyInfoState());
      dispatch(getCompanyInfo({ companyId: companyId }));
    } else {
      navigate("/company");
    }
  }, [companyId]);

  const OnEditorStateChange = (state) => {
    setEditorState(state);
  };

  const HandleChangeEvent = (variableName, input) => {
    dispatch(ChangeCompanyInfoState({ [variableName]: input }));
  };

  const HandleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();

    if (logo) {
      formData.append("logo", logo);
    }
    if (icon) {
      formData.append("icon", icon);
    }

    formData.append("companyId", companyId);
    formData.append("mailContent", draftToHtml(convertToRaw(editorState?.getCurrentContent())));

    ApiHelper.xmlPostFormData(CompanyInfoApiUrls.upsertCompanyInfo, formData, token, (res) => {
      dispatch(getCompanyInfo({ companyId: companyId }));
      alert(JSON.parse(res.response).message);
    });
  };

  const DeleteImage = (type, imageName) => {
    const typeEnum = GetValueByKey(type, imageTypeEnum);
    dispatch(deleteImageFromCompanyInfo({ type: typeEnum, imageName: imageName, companyInfoId: companyInfo.id }));
  };

  return (
    <div>
      {isLoading && <Overlay />}
      <Form onSubmit={HandleSubmit}>
        <BreadCrumb
          pageName={t("companyInfoBreadcrumbLabel")}
          backPageName={t("companiesBreadCrumbLabel")}
          backUrl="/Company"
        >
          <Row className={"justify-content-between"}>
            <Col xs={"auto"}>
              <h3>
                {t("companyName")} : {companyName}
              </h3>
            </Col>
            <Col xs={"auto"}>
              <Row>
                <Col>
                  <Button
                    variant="danger"
                    className={"px-4"}
                    onClick={() => {
                      dispatch(deleteCompanyInfo({ id: companyId }));
                      dispatch(ClearCompanyInfoState());
                      setPreviewLogo("");
                      setPreviewIcon("");
                    }}
                  >
                    {t("deleteButtonLabel")}
                  </Button>
                </Col>
                <Col>
                  <Button variant="primary" type={"submit"} className={"px-4"}>
                    {t("saveChangesButtonLabel")}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </BreadCrumb>
        <Row className="justify-content-center my-4">
          <Col xs={"auto"}>
            <Card>
              <label className="admin-setting-page-add-image-label" style={{ width: "350px" }}>
                <input
                  id="admin-excel-input"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    if (file) {
                      setLogo(file);
                      setPreviewLogo(URL.createObjectURL(file));
                    } else {
                      // Reset state if no file is selected
                      setLogo(null);
                      setPreviewLogo(null);
                    }
                  }}
                  type="file"
                  accept=".jpeg, .png, .webp, .jpg"
                />
                <IconSvg icon="add-image" />

                <span> {t("uploadLogo")} ( 300x100 ) </span>
              </label>
              {(companyInfo.logoPath || previewLogo) && (
                <>
                  <div className="logo-previev-img-container">
                    <img
                      height="100"
                      alt={"Company Icon"}
                      src={previewLogo ? previewLogo : ApiHelper.baseURL + "wwwroot/logo/" + companyInfo.logoPath}
                      width="300"
                    />
                  </div>
                  <Button
                    size="sm"
                    variant="danger"
                    style={{ margin: "10px" }}
                    onClick={() => {
                      DeleteImage("CompanyLogo", companyInfo.logoPath);
                    }}
                  >
                    <IconSvg icon="trash-can" fill="#fff" />
                  </Button>
                </>
              )}
            </Card>
          </Col>
          <Col xs={"auto"}>
            <Card>
              <label className="admin-setting-page-add-image-label" style={{ width: "350px" }}>
                <input
                  id="admin-excel-input"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    if (file) {
                      setIcon(file);
                      setPreviewIcon(URL.createObjectURL(file));
                    } else {
                      // Reset state if no file is selected
                      setIcon(null);
                      setPreviewIcon(null);
                    }
                  }}
                  type="file"
                  accept=".jpeg, .png, .webp, .jpg"
                />
                <IconSvg icon="add-image" />

                <span> {t("uploadIcon")} ( 50x50 ) </span>
              </label>
              {(companyInfo.iconPath || previewIcon) && (
                <>
                  <div className="logo-previev-img-container">
                    <img
                      height="50"
                      alt={"Company Logo"}
                      src={previewIcon ? previewIcon : ApiHelper.baseURL + "wwwroot/logo/" + companyInfo.iconPath}
                      width="50"
                    />
                  </div>
                  <Button
                    size="sm"
                    variant="danger"
                    style={{ margin: "10px" }}
                    onClick={() => {
                      DeleteImage("CompanyIcon", companyInfo.iconPath);
                    }}
                  >
                    <IconSvg icon="trash-can" fill="#fff" />
                  </Button>
                </>
              )}
            </Card>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col>
            <h3 className="admin-settings-input-header">{t("theTextOfTheInvitationMailSentToTheCandidate")}</h3>

            <Card className={"p-3"}>
              <Editor
                editorState={editorState ? editorState : companyInfo.mailContent}
                onEditorStateChange={OnEditorStateChange}
              />
            </Card>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
