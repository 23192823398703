export const ParseDate = (dateString) => {
  //TODO: this can be used globally. DISCUSS

  const dateObject = new Date(dateString);

  const year = dateObject?.getFullYear().toString().substring(2);
  const month = dateObject?.getMonth().toString().padStart(2, "0");
  const day = dateObject?.getDate().toString().padStart(2, "0");
  const hour = dateObject?.getHours().toString().padStart(2, "0");
  const minute = dateObject?.getMinutes().toString().padStart(2, "0");

  const time = `${hour}:${minute}`;
  const date = `${day}-${month}-${year}`;

  return { time: time, date: date };
};
