import { createSlice } from "@reduxjs/toolkit";
import { deleteComment, getCandidateById, getCommentsByCandidateId, upsertComment } from "./Thunk";

export const candidate = createSlice({
  name: "candidate",
  initialState: {
    id: "",
    name: "",
    candidate: {},
    comments: [],
    selectedComment: null,

    isLoading: false,
  },
  reducers: {
    SetSelectedComment(state, action) {
      state.selectedComment = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCandidateById.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getCandidateById.fulfilled, (state, action) => {
      state.isLoading = false;
      state.candidate = action.payload;
    });

    builder.addCase(getCommentsByCandidateId.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getCommentsByCandidateId.fulfilled, (state, action) => {
      state.isLoading = false;
      state.comments = action.payload;
    });

    builder.addCase(upsertComment.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(upsertComment.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(deleteComment.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deleteComment.fulfilled, (state, action) => {
      state.isLoading = false;
    });
  },
});

export const { SetSelectedComment } = candidate.actions;

export default candidate.reducer;
