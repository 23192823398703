import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { processApplicationData } from "../Utils/utils";
import Overlay from "../../../../SharedComponents/Overlay";
import { getAssessments } from "../../Assessment/Store/Thunk";
import { HandleUpdateOperation, UpdateAssessment, UpdateName } from "../Store";

import { CreateInventory, UpdateInventory, uploadApplicationBackUp } from "../Store/Thunk";

import InventoryList from "./InventoryList/InventoryList";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Card from "react-bootstrap/Card";
import Select from "react-select";

export default function Inventory() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLoading1 = useSelector((state) => state.inventory.isLoading);
  const isLoading2 = useSelector((state) => state.assessment.isLoading);
  const selectedAssessments = useSelector((state) => state.inventory.selectedAssessments);
  const inventoryId = useSelector((state) => state.inventory.inventoryId);
  const isUpdateOperation = useSelector((state) => state.inventory.isUpdateOperation);
  const name = useSelector((state) => state.inventory.name);
  const isLoading = isLoading1 || isLoading2;
  const [mappedAssessmentList, setMappedAssessmentList] = useState([]);

  const assessmentList = useSelector((state) => state.assessment.assessmentList);

  useEffect(() => {
    dispatch(getAssessments());
    setMappedAssessmentList(HandleMappingAssesments());
  }, []);

  const HandleMappingAssesments = () => {
    return assessmentList.map((x) => ({
      label: x.isB2b ? x.name + "B2b" : x.name + "B2c",
      name: x.name,
      id: x.id,
      value: x.id,
    }));
  };

  const submitForm = (event) => {
    event.preventDefault();
    if (!isUpdateOperation) {
      dispatch(
        CreateInventory({
          name,
          assessmentIds: selectedAssessments.map((x) => x.id),
        })
      );
    } else {
      dispatch(
        UpdateInventory({
          name: name,
          assessmentIds: selectedAssessments.map((x) => x.id),
          id: inventoryId,
        })
      );
    }

    event.currentTarget.reset();
    ClearInputs();
  };

  const UploadApplicationBackup = (event) => {
    const file = event.target.files[0];
    const fileReader = new FileReader();

    fileReader.onload = (event) => {
      const content = event.target.result;
      const applicationData = JSON.parse(content);
      const processedData = processApplicationData(applicationData);
      dispatch(uploadApplicationBackUp(processedData));
    };

    if (file) {
      fileReader.readAsText(file);
    }
  };

  const HandleAssessmentSelect = (val) => {
    dispatch(UpdateAssessment(val));
  };

  const ClearInputs = () => {
    dispatch(
      HandleUpdateOperation({
        name: "",
        selectedAssessments: [],
        isUpdateOperation: false,
        inventoryId: 0,
      })
    );
  };

  return (
    <div>
      {isLoading && <Overlay />}
      <Card body className="mb-3">
        <Form onSubmit={submitForm}>
          <Row>
            <Col xs={12} md={5}>
              <h6>{t("inventoryName")}</h6>
              <InputGroup className="mb-3">
                <Form.Control
                  required
                  name="name"
                  placeholder={t("enterInventoryName")}
                  value={name}
                  onChange={(e) => dispatch(UpdateName(e.target.value))}
                />
              </InputGroup>
            </Col>
            <Col xs={12} md={5}>
              <Row>
                <Col>
                  <h6>{t("Assessments")}</h6>
                </Col>
                <Col>
                  <input
                    style={{ maginTop: "-3px" }}
                    type="file"
                    accept="application/json"
                    onChange={(e) => UploadApplicationBackup(e)}
                  />
                </Col>
              </Row>
              <Select
                value={selectedAssessments}
                options={mappedAssessmentList}
                placeholder={"Değerleme seçiniz"}
                onChange={HandleAssessmentSelect}
                closeMenuOnSelect={false}
                isMulti
              />
            </Col>
            <Col xs={12} md={2} style={{ marginBottom: "0px !important" }}>
              <Row style={{ height: !isUpdateOperation && "100%", margin: 0 }}>
                <Button
                  className="mb-1"
                  type="submit"
                  style={{ width: "100%", height: "100%" }}
                  variant="primary"
                  disabled={!name}
                >
                  {isUpdateOperation ? t("update") : t("create")}
                </Button>
              </Row>
              {isUpdateOperation && (
                <Row style={{ margin: 0 }}>
                  <Button style={{ width: "100%", height: "100%" }} variant="secondary" onClick={ClearInputs}>
                    {t("decline")}
                  </Button>
                </Row>
              )}
            </Col>
          </Row>
        </Form>
      </Card>
      <h5>{t("currentInventories")}</h5>
      <InventoryList />
    </div>
  );
}
