import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Overlay from "../../../../SharedComponents/Overlay";

export default function Project() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.projects.isLoading);

  return (
    <div>
      {isLoading && <Overlay />}
      EMPTY PAGE, TO BE FILLED
    </div>
  );
}
