import { createSlice } from "@reduxjs/toolkit";
import {
  getScopes,
  createScope,
  deleteScope,
  updateScope,
  getSubScope,
  createSubScope,
  deleteSubScope,
  updateSubScope,
} from "./Thunk";

export const scope = createSlice({
  name: "scope",
  initialState: {
    scopeModal: {
      isShow: false,
      data: {
        name: "",
        description: "",
      },
    },
    subScopeModal: {
      isShow: false,
      data: {
        name: "",
        description: "",
      },
    },
    scopes: [],
    subScopes: [],
    currentApplication: null,
  },
  reducers: {
    openScopeModal(state, action) {
      state.scopeModal.isShow = true;
      state.scopeModal.data = action.payload;
    },
    updateCurrentApplication(state, action) {
      state.currentApplication = action.payload;
    },
    closeScopeModal(state) {
      state.scopeModal.isShow = false;
      state.scopeModal.data = {
        name: "",
        description: "",
      };
    },
    openSubScopeModal(state, action) {
      state.subScopeModal.isShow = true;
      state.subScopeModal.data = action.payload;
    },
    closeSubScopeModal(state) {
      state.subScopeModal.isShow = false;
      state.subScopeModal.data = {
        name: "",
        description: "",
      };
    },
    clearScopes(state) {
      state.scopes = [];
    },
    clearSubScopes(state) {
      state.subScopes = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getScopes.fulfilled, (state, action) => {
      state.scopes = action.payload?.scopes;
    });
    builder.addCase(createScope.fulfilled, (state, action) => {
      state.scopes = action.payload?.scopes;
    });
    builder.addCase(deleteScope.fulfilled, (state, action) => {
      state.scopes = action.payload?.scopes;
    });
    builder.addCase(updateScope.fulfilled, (state, action) => {
      state.scopes = action.payload?.scopes;
    });
    builder.addCase(getSubScope.fulfilled, (state, action) => {
      state.subScopes = action.payload;
    });
    builder.addCase(createSubScope.fulfilled, (state, action) => {
      state.subScopes = action.payload;
    });
    builder.addCase(deleteSubScope.fulfilled, (state, action) => {
      state.subScopes = action.payload;
    });
    builder.addCase(updateSubScope.fulfilled, (state, action) => {
      state.subScopes = action.payload;
    });
  },
});

export const {
  openScopeModal,
  closeScopeModal,
  openSubScopeModal,
  closeSubScopeModal,
  clearScopes,
  clearSubScopes,
  updateCurrentApplication,
} = scope.actions;

export default scope.reducer;
