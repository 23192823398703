import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { createDualTextuary, deleteDualTextuary, getDualTextuaries, updateDualTextuary } from "../Store/Thunk";

import { setIsDual } from "../../../ScoringScale/Store";
import ReportItem from "../../ReportItem/Components/ReportItem";
import { updateDualTextuaryState } from "../Store";

export default function DualTextuary() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const dualTextuaryState = useSelector((state) => state.dualTextuary);

  useEffect(() => {
    dispatch(setIsDual(true));
    let isSub = state.subScope ? true : false;
    let scopeId = state.subScope ? state.subScope.id : state.scope.id;
    dispatch(getDualTextuaries({ id: scopeId, isSub }));
    dispatch(updateDualTextuaryState({ scopeId, isSub }));
  }, []);

  const CreateDualTextuary = (val) => {
    dispatch(createDualTextuary(val));
  };

  const UpdateDualTextuary = (val) => {
    dispatch(updateDualTextuary(val));
  };

  return (
    <div>
      <ReportItem
        keyword="Dual"
        state={state}
        pageName={t("createDualTextuary")}
        CreateFunction={CreateDualTextuary}
        UpdateFunction={UpdateDualTextuary}
        DeleteFunction={deleteDualTextuary}
        entityList={dualTextuaryState.dualTextuaryEntities}
        isLoading={dualTextuaryState.isLoading}
        isDual={true}
      />
    </div>
  );
}
