import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import PositionList from "./PositionList";
import UpsertPositionModal from "./UpsertPositionModal";
import { ChangeIsUpdateOperation, ShowUpsertPositionModal } from "../Store";
import Overlay from "../../../../SharedComponents/Overlay";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import IconSvg from "../../../../App/AppMenu/IconSvg";
import ScrollableCard from "../../../../SharedComponents/ScrollableCard";

export default function CreatePosition() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.position.isLoading);
  // const companyId = state?.id;
  const companyId = useSelector((state) => state.adminData.companyUser.company.companyId);

  const HandleCreateClick = () => {
    dispatch(ChangeIsUpdateOperation({ isUpdateOperation: false }));
    dispatch(ShowUpsertPositionModal({ upsertPositionModalIsShown: true }));
  };
  return (
    <div>
      {isLoading && <Overlay />}

      <Form>
        <Row className={"justify-content-between mx-0"} style={{ height: "42px" }}>
          <Col className={"align-content-center"}>
            {/*<FloatingLabel controlId={"floatingPositionDescription"} label={"ara"}>*/}
            <Form.Control placeholder={"ara"} className={"h-100"}></Form.Control>
          </Col>
          <Col xs={"auto"} className={"align-content-center"}>
            <Button className={"button secondary"} onClick={HandleCreateClick} size={"sm"}>
              <Row>
                <Col xs={"auto"} className={"align-content-center"}>
                  <IconSvg icon={"plusV2"} height={22} width={22}></IconSvg>
                </Col>
                <Col xs={"auto"} className={"text-20 fw-medium"}>
                  {t("createNewPositionButtonLabel")}
                </Col>
              </Row>
            </Button>
          </Col>
        </Row>
      </Form>

      <ScrollableCard>
        <PositionList></PositionList>
      </ScrollableCard>
      <UpsertPositionModal companyId={companyId}></UpsertPositionModal>
    </div>
  );
}
