import { createAsyncThunk } from "@reduxjs/toolkit";
import PositionAPI, { PositionApiUrls } from "../API/PositionAPI";

import LocalStorage from "../../../../Services/Storage/LocalStorage";
import StorageModel from "../../../../Models/StorageModel";
import HttpsClient from "../../../../Services/HttpsClient";
import ApiHelper from "../../../../Services/ApiHelper";
import Cache from "../../../../Services/Cache";

const storage = new LocalStorage();
const storageModel = new StorageModel(storage);
const cache = new Cache(storageModel);

const httpsClient = new HttpsClient();
const apiHelper = new ApiHelper(httpsClient);
const positionAPI = new PositionAPI(apiHelper, cache);

export const getPositionById = createAsyncThunk(
  PositionApiUrls.getPositionById,
  async (payload, { getState, dispatch }) => {
    const state = getState();
    const token = state.auth.user.token;
    const response = await positionAPI.getPositionById(payload, token);

    return response;
  }
);
