import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import BreadCrumb from "../../../../../Components/Dashboard/BreadCrumb";
import CreateScope from "../CreateScope/CreateScope";
import ScopeList from "./ScopeList";
import ScopeModal from "./ScopeModal";
import { createScope } from "../../Store/Thunk";
import { updateCurrentApplication } from "../../Store";

export default function Scope() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { state } = useLocation();
  const application = state;
  var applicationType = application.applicationType;

  useState(() => {
    dispatch(updateCurrentApplication(application));
  }, []);

  const CreateScopeEntity = (scope) => {
    scope.applicationId = application.id;
    scope.applicationType = applicationType;
    dispatch(createScope({ scope }));
  };

  return (
    <div>
      <BreadCrumb
        pageName={t("scopePageHeaderLabel")}
        backPageName={application.isTest ? t("tests") : t("inventories")}
        backUrl={application.isTest ? "/test" : "/inventory"}
      >
        <h3>
          {application.isTest ? t("Test") : t("inventory")} : {application.name}
        </h3>
      </BreadCrumb>

      <CreateScope CreateScope={CreateScopeEntity} application={application} uiString={t("scope")} />

      <h5>
        {application.name} {t("scopes")}
      </h5>

      <ScopeList />
      <ScopeModal />
    </div>
  );
}
