import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Overlay from "../../../../SharedComponents/Overlay";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ApplicationCardList from "./Applications/ApplicationCardList";
// import Statistics from "./Statistics/Statistics";

const AdminDash = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.adminData.companyUser.company.companyId);
  const companyCredits = useSelector((state) => state.adminDash.companyCredits);

  const isLoading = useSelector((state) => state.adminDash.isLoading);

  useEffect(() => {}, []);

  return (
    <div className={"page-background"}>
      {isLoading && <Overlay />}
      <Row className={"px-4 pt-4 m-0"}>
        <div className={"text-14 text-color-gray-light "}>DEĞERLEMELER</div>
      </Row>
      <Row>
        <ApplicationCardList />
      </Row>

      <Row>
        <Col xs={12} lg={9}>
          <Row className={"px-4 m-0"}>
            <div className={"text-14 text-color-gray-light"}>BİLDİRİMLER</div>
          </Row>
        </Col>
        <Col xs={12} lg={3}>
          <Row className={"px-4 m-0"}>
            <div className={"text-14 text-color-gray-light text-center"}>TAMAMLANAN DEĞERLEMELER</div>
          </Row>
          {/* <Statistics></Statistics> */}
        </Col>
      </Row>
    </div>
  );
};
export default AdminDash;
