//TODO: discuss if enumObjects should be imported in components, or should we call them by their names here ?

export const GetKeyByValue1 = (keyToMatch, enumObject) => {
  return Object.keys(enumObject).find((key) => enumObject[key] === keyToMatch);
  //TODO: this is an alternative, discuss which to use
};

export const GetValueByKey = (keyToMatch, enumObject) => {
  return enumObject[keyToMatch];
};

export const GetKeyByValue = (valueToMatch, enumObject) => {
  for (var name in enumObject) {
    var value = enumObject[name];
    if (value === valueToMatch) return name;
  }
};

export const MapObjectIntoArray = (enumObject) => {
  let Array = [];
  return Object.assign({}, ...Object.entries(enumObject).map(([key, value]) => (Array = [...Array, { [key]: value }])));
};

export const designTypes = {
  Detail_1: 0,
  DualText: 1,
  MonoText: 2,
  Summary_1: 3,
  Summary_LoadingBar: 4,
  Detail_LoadingBar: 5,
  Detail_2: 6,
  MonoText_2: 7,
  AnsweringTendencyDesign_1: 8,
  TestScaleDesign_1: 9,
  Summary_2: 10,
  Detail_3: 11,
  Detail_4: 12,
  Detail_5: 13,
};

export const companyUserEnum = {
  Manager: 0,
  Recruiter: 1,
};

export const applicationTypeEnum = {
  Test: 0,
  Inventory: 1,
  Survey: 2,
  Assessment: 3,
};

export const imageTypeEnum = {
  ApplicationIcon: 0,
  PdfCover: 1,
  PdfInfoPage: 2,
  CompanyIcon: 3,
  CompanyLogo: 4,
};
