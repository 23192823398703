import React, { useEffect } from "react";

import { getTests } from "../Store/Thunk";
import UpdateTestModal from "./Modal/UpdateTestModal";
import TestEntityListRow from "./ApplicationItem/TestEntityListRow";

import TestInfoModal from "./Modal/TestInfoModal";
import { useDispatch, useSelector } from "react-redux";
import "../Styles/index.css";
import Overlay from "../../../../SharedComponents/Overlay";

export default function TestEntityList() {
  const dispatch = useDispatch();
  const tests = useSelector((state) => state.testEntityList.tests);
  const isShowInfoModal = useSelector((state) => state.testEntityList.infoModal.isShow);

  const isLoading = useSelector((state) => state.testEntityList.isLoading);

  useEffect(() => {
    dispatch(getTests());
  }, []);

  return (
    <>
      {isLoading && <Overlay />}
      {tests?.length > 0 &&
        tests.map((test, i) => {
          let editedTest = { ...test };
          editedTest.isTest = true;
          return <TestEntityListRow test={editedTest} key={i} />;
        })}

      <UpdateTestModal />

      {isShowInfoModal && <TestInfoModal />}
    </>
  );
}
