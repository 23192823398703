import React, { useEffect, useState } from "react";
import ApiHelper from "../../../../Helpers/ApiHelper";
import UseAuthHelper from "../../../../Helpers/Auth/UseAuthHelper";
import BreadCrumb from "../../../../Components/Dashboard/BreadCrumb";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import IconSvg from "../../../../App/AppMenu/IconSvg";
import { Button, Card, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import "animate.css";

export default function InventoryAnswers() {
  const { auth } = UseAuthHelper();
  const user = auth?.data;
  const { t } = useTranslation();
  const { state } = useLocation();
  const inventory = state;
  const [savedAnswers, setSavedAnswers] = useState([]);
  const [optionInput, setOptionInput] = useState("");

  useEffect(() => {
    GetAnswers();
  }, []);

  const [enteredOptions, setEnteredOptions] = useState([]);

  let GetAnswers = () => {
    ApiHelper.getAsync(
      "inventory/GetLikertAnswers",
      { inventoryId: inventory.id },
      function (result) {
        if (result.data.statusCode) {
          setSavedAnswers(result.data.data);
          setEnteredOptions(result.data.data.options.map((x) => x.text));
          return;
        }
      },
      user.token
    );
  };

  let DeleteAnswer = (id) => {
    ApiHelper.getAsync(
      "inventory/DeleteLikertAnswers",
      { inventoryId: inventory.id },
      function () {
        GetAnswers();
      },
      user.token
    );
  };

  let CreateAnswers = () => {
    let postObj = {
      inventoryId: inventory.id,
      options: enteredOptions.map((x) => ({
        text: x,
      })),
    };
    ApiHelper.postAsync(
      "inventory/CreateLikertAnswers",
      postObj,
      function () {
        GetAnswers();
      },
      user.token
    );
  };

  let AddOption = () => {
    if (optionInput !== null && optionInput !== "") {
      setEnteredOptions((options) => [...options, optionInput]);
      setOptionInput("");
    }
  };

  return (
    <div>
      <BreadCrumb pageName={t("addInventoryAnswers")} backPageName={t("inventories")} backUrl="/inventory">
        <Row>
          <Col>
            <h3>Enavanter Adı : {inventory.name}</h3>
          </Col>
          <Col>
            <Row xs="auto" className="justify-content-md-end">
              <Col>
                <Button
                  style={{ height: "100%", width: "100%" }}
                  variant="primary"
                  onClick={CreateAnswers}
                  disabled={!enteredOptions.length}
                >
                  Kaydet
                </Button>
              </Col>
              {savedAnswers?.options && (
                <Col>
                  <Button variant="danger" onClick={DeleteAnswer} style={{ float: "right" }}>
                    Mevcut Cevap Grubunu Sil
                  </Button>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </BreadCrumb>
      <Container>
        <Card body>
          <Row style={{ marginRight: 0 }}>
            <Col xs={8}>
              <Row>
                <InputGroup className="mb-3">
                  <Form.Control
                    value={optionInput}
                    placeholder="Cevap giriniz"
                    onChange={(e) => setOptionInput(e.target.value)}
                  />
                </InputGroup>
              </Row>

              <Row xs="auto">
                {enteredOptions.map((opt, i) => (
                  <Col key={i}>
                    {i + 1}.{opt}
                  </Col>
                ))}
                {enteredOptions.length ? (
                  <Col>
                    <Button variant="danger" size="sm" onClick={() => setEnteredOptions([])}>
                      <IconSvg icon="sweep" />
                    </Button>
                  </Col>
                ) : (
                  ""
                )}
              </Row>
            </Col>
            <Col xs={4}>
              <Row className="mb-2">
                <Button
                  style={{ height: "100%", width: "100%" }}
                  variant="primary"
                  onClick={AddOption}
                  disabled={optionInput !== "" && optionInput !== null ? false : true}
                >
                  Ekle
                </Button>
              </Row>
            </Col>
          </Row>
        </Card>
        {savedAnswers?.options && (
          <Container style={{ marginTop: "30px" }} className="animate__animated  animate__fadeInDown animate__faster">
            <h3 style={{ textAlign: "center" }}> Mevcut Cevap Grubu </h3>

            {savedAnswers?.options?.map((a, i) => {
              return (
                <Row className="justify-content-center" style={{ margin: "5px", width: "100%" }} key={i}>
                  <Col xs="10" md="8" lg="4">
                    <button id={i + 1} className="answer-btn animate__animated animate__faster">
                      <span style={{ float: "left" }}>{i + 1}</span> {a.text}
                    </button>
                  </Col>
                </Row>
              );
            })}
          </Container>
        )}
      </Container>
    </div>
  );
}
