import { createSlice } from "@reduxjs/toolkit";
import { deleteCompanyInfo, deleteImageFromCompanyInfo, getCompanyInfo, upsertCompanyInfo } from "./Thunk";
import { ContentState, EditorState } from "draft-js";
import htmlToDraft from "html-to-draftjs";

export const companyInfo = createSlice({
  name: "companyInfo",
  initialState: {
    id: "",
    iconPath: "",
    logoPath: "",
    mailContent: "",
    isLoading: false,
  },
  reducers: {
    ChangeCompanyInfoState(state, action) {
      Object.assign(state, action.payload);
    },
    ClearCompanyInfoState(state, action) {
      state.iconPath = "";
      state.logoPath = "";
      state.id = "";
      state.mailContent = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(upsertCompanyInfo.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(upsertCompanyInfo.fulfilled, (state, action) => {
      state.isLoading = false;
      console.log(action.payload);
    });

    builder.addCase(getCompanyInfo.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getCompanyInfo.fulfilled, (state, action) => {
      state.companyId = action.payload?.companyId;
      state.id = action.payload?.id;
      state.logoPath = action.payload?.logoPath;
      state.iconPath = action.payload?.iconPath;
      state.mailContent = action.payload?.mailContent;

      const prevHtml = action.payload?.mailContent
        ? action.payload?.mailContent
        : "<p> Uygulama bilgilendirme sayfası içeriğini giriniz</p>";

      const contentBlock = htmlToDraft(prevHtml);
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      state.mailContent = EditorState.createWithContent(contentState);

      state.isLoading = false;
    });

    builder.addCase(deleteCompanyInfo.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deleteCompanyInfo.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(deleteImageFromCompanyInfo.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deleteImageFromCompanyInfo.fulfilled, (state, action) => {
      state.isLoading = false;
    });
  },
});

export const { ChangeCompanyInfoState, UpdateCompanyId, ClearCompanyInfoState } = companyInfo.actions;

export default companyInfo.reducer;
