import React from "react";
import { Badge, Button, Card, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { HandleMappingApplications, UpdateAssessmentState } from "../Store";
import { deleteAssessment } from "../Store/Thunk";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function AssessmentList() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const assessmentList = useSelector((state) => state.assessment.assessmentList);
  const navigate = useNavigate();

  const HandleDeleteButton = (id) => {
    dispatch(deleteAssessment({ id }));
  };

  const HandleUpdateButton = (assess) => {
    var obj = {
      id: assess.id,
      name: assess.name,
      isB2b: assess.isB2b,
      price: assess.price,
      isUpdateOperation: true,
      selectedApplications: HandleMappingApplications(assess.inventories, assess.tests, assess.surveys),
    };

    dispatch(UpdateAssessmentState(obj));
  };
  console.log(assessmentList);
  return (
    assessmentList &&
    assessmentList.map((assessment, i) => (
      <Card className="mb-3" key={"assess" + i}>
        <Card.Body>
          <Row xs="auto">
            <Col xs="1">{assessment.id}</Col>
            <Col xs="12" md="3">
              {assessment.name}
            </Col>
            <Col md="1" style={{ color: assessment.isB2b ? "green" : "red", fontWeight: 700 }}>
              {assessment.price}
            </Col>
            <Col xs="12" md="5">
              <Row xs="auto">
                {assessment.inventories &&
                  assessment.inventories.map((inv, j) => (
                    <Col key={j + "inv"}>
                      <Badge bg="primary">{inv.name}</Badge>
                    </Col>
                  ))}
                {assessment.tests &&
                  assessment.tests.map((test, k) => (
                    <Col key={"te" + k}>
                      <Badge bg="secondary">{test.name}</Badge>
                    </Col>
                  ))}
                {assessment.surveys &&
                  assessment.surveys.map((survey, m) => (
                    <Col key={"te" + m}>
                      <Badge bg="warning">{survey.name}</Badge>
                    </Col>
                  ))}
              </Row>
            </Col>
            <Col md="2">
              <Row xs="auto" className="justify-content-md-end">
                <Col>
                  <Button
                    size={"sm"}
                    onClick={() => {
                      navigate("/info", { state: assessment });
                    }}
                  >
                    {t("infoButtonLabel")}
                  </Button>
                </Col>
                <Col>
                  <Button size="sm" variant="success" onClick={() => HandleUpdateButton(assessment)}>
                    Düzenle
                  </Button>
                </Col>
                <Col>
                  <Button
                    style={{ float: "right" }}
                    variant="danger"
                    size="sm"
                    onClick={() => HandleDeleteButton(assessment.id)}
                  >
                    Sil
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    ))
  );
}
