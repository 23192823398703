import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import BreadCrumb from "../../../Dashboard/BreadCrumb";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";

import UseAuthHelper from "../../../../Helpers/Auth/UseAuthHelper";
import ApiHelper from "../../../../Helpers/ApiHelper";

export function Polar(props) {
  const { auth } = UseAuthHelper();
  const { state } = useLocation();
  const isSub = state.isSub;
  const user = auth?.data;
  const [scopeQuestion, setScopeQuestion] = useState({
    QuestionDescription: "",
    ScopeId: state.scope?.id,
    SubScopeId: state.subScope?.id,
    polared: {
      leftPolar: "",
      rightPolar: "",
      reverseItem: false,
    },
  });

  const [ScopeQuestions, setScopeQuestions] = useState();

  const [modal, setModalShow] = useState({
    show: false,
    data: {
      questionDescription: "",
      polared: { leftPolar: "", rightPolar: "" },
    },
  });

  useEffect(() => {
    if (!ScopeQuestions) {
      RefreshScopeQuestions();
    }
  });

  let CreateScopeQuestion = () => {
    const url = isSub ? "question/CreateSubPolared" : "question/CreatePolared";
    ApiHelper.postAsync(
      url,
      scopeQuestion,
      function (result) {
        RefreshScopeQuestions();
      },
      user.token
    );
  };

  let RefreshScopeQuestions = () => {
    const url = isSub ? "question/GetSubPolareds" : "question/GetPolareds";
    const id = isSub ? state.subScope?.id : state.scope?.id;

    ApiHelper.getAsync(
      url,
      { id },
      function (result) {
        setScopeQuestions(result.data.data);
      },
      user.token
    );
  };

  let DeleteScopeQuestion = (id) => {
    ApiHelper.getAsync(
      "question/DeleteQuestion",
      { id: id },
      function (result) {
        RefreshScopeQuestions();
      },
      user.token
    );
  };

  let UpdateScopeQuestion = () => {
    ApiHelper.postAsync(
      "question/UpdatePolared",
      {
        id: modal.data.id,
        ScopeId: state.scope.id,
        SubScopeId: state.subScope.id,
        polared: {
          id: modal.data.polared.id,
          LeftPolar: modal.data.polared.leftPolar,
          RightPolar: modal.data.polared.rightPolar,
          reverseItem: modal.data.polared.reverseItem,
        },
      },
      function (result) {
        setModalShow({
          show: false,
          data: {
            polared: { leftPolar: "", rightPolar: "" },
          },
        });
        RefreshScopeQuestions();
      },
      user.token
    );
  };

  return (
    <div>
      <BreadCrumb
        pageName="Kutuplu Soru"
        backPageName={isSub ? "Boyutlar" : "Alt Boyutlar"}
        backUrl={isSub ? "/subscope" : "/scope"}
        backState={
          isSub ? { state: { scope: state.scope, application: state.application } } : { state: state.application }
        }
      >
        <Row>
          <Col>
            <h6>Envanter adı : {state.application.name}</h6>
          </Col>
          <Col>
            <h6>Boyut adı : {state.scope.name}</h6>
          </Col>
          {isSub && (
            <Col>
              <h6>Alt Boyut : {state.subScope?.name}</h6>
            </Col>
          )}
        </Row>
      </BreadCrumb>
      <Card body className="mb-3">
        <Row>
          <Col xs={12} md={10}>
            <Row>
              <Col>
                <Row>
                  <Col xs="6">
                    <h6>Sol Kutup Metni </h6>
                  </Col>
                  <Col xs="6">
                    <Form.Check
                      type="switch"
                      label="Reverse Item"
                      onChange={(e) =>
                        setScopeQuestion((q) => ({
                          ...q,
                          polared: {
                            ...q.polared,
                            reverseItem: e.target.checked,
                          },
                        }))
                      }
                    />
                  </Col>
                </Row>
                <InputGroup className="mb-3">
                  <Form.Control
                    value={scopeQuestion.polared?.leftPolar}
                    placeholder="sol kutup giriniz"
                    onChange={(e) =>
                      setScopeQuestion((q) => ({
                        ...q,
                        polared: { ...q.polared, leftPolar: e.target.value },
                      }))
                    }
                  />
                </InputGroup>
              </Col>
              <Col>
                <h6>Sağ Kutup Metni </h6>
                <InputGroup className="mb-3">
                  <Form.Control
                    value={scopeQuestion.polared?.rightPolar}
                    placeholder="sağ kutup giriniz"
                    onChange={(e) =>
                      setScopeQuestion((q) => ({
                        ...q,
                        polared: { ...q.polared, rightPolar: e.target.value },
                      }))
                    }
                  />
                </InputGroup>
              </Col>
            </Row>
          </Col>

          <Col xs={12} md={2} className="mb-6">
            <Button
              style={{ height: "100%", width: "100%" }}
              variant="primary"
              onClick={CreateScopeQuestion}
              disabled={scopeQuestion.polared.rightPolar && scopeQuestion.polared.leftPolar ? false : true}
            >
              Oluştur
            </Button>
          </Col>
        </Row>
      </Card>

      {ScopeQuestions?.length > 0 &&
        ScopeQuestions.map((sq, i) => {
          return (
            <Card key={i} className="mb-3">
              <Card.Body>
                <Row>
                  <Col xs="12" md="1">
                    {sq.id}
                  </Col>
                  <Col xs="12" md="4">
                    Sol Kutup : {sq.polared.leftPolar}
                  </Col>
                  <Col xs="12" md="4">
                    Sağ Kutup : {sq.polared.rightPolar}
                  </Col>
                  <Col xs="12" md="1">
                    {sq.polared.reverseItem && "Reverse"} {sq.order}
                  </Col>
                  <Col> </Col>
                  <Col xs="auto" className="justiy-content-md-end">
                    <Row>
                      <Col>
                        <Button size="sm" variant="success" onClick={() => setModalShow({ show: true, data: sq })}>
                          Düzenle
                        </Button>
                      </Col>
                      <Col>
                        <Button size="sm" variant="danger" onClick={() => DeleteScopeQuestion(sq.id)}>
                          Sil
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          );
        })}
      <Modal
        show={modal.show}
        onHide={() => {
          setModalShow({
            show: false,
            data: {
              questionDescription: "",
              polared: { leftPolar: "", rightPolar: "" },
            },
          });
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Kutuplu Soru Güncelle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <h5>Sol Kutup metni </h5>
            </Col>
            <Col>
              <Form.Check
                type="switch"
                label="Reverse Item"
                checked={modal.data?.polared?.reverseItem}
                onChange={(e) =>
                  setModalShow((modal) => ({
                    ...modal,
                    data: {
                      ...modal.data,
                      polared: {
                        ...modal.data.polared,
                        reverseItem: e.target.checked,
                      },
                    },
                  }))
                }
              />
            </Col>
          </Row>

          <InputGroup className="mb-3">
            <Form.Control
              placeholder="Soru metini"
              value={modal.data?.polared?.leftPolar}
              onChange={(e) =>
                setModalShow((modal) => ({
                  ...modal,
                  data: {
                    ...modal.data,
                    polared: {
                      ...modal.data.polared,
                      leftPolar: e.target.value,
                    },
                  },
                }))
              }
            />
          </InputGroup>
          <h5>Sağ Kutup metni </h5>
          <InputGroup className="mb-3">
            <Form.Control
              placeholder="Soru metini"
              value={modal.data?.polared?.rightPolar}
              onChange={(e) =>
                setModalShow((modal) => ({
                  ...modal,
                  data: {
                    ...modal.data,
                    polared: {
                      ...modal.data.polared,
                      rightPolar: e.target.value,
                    },
                  },
                }))
              }
            />
          </InputGroup>
          <h5>Soru açıklaması </h5>
          <InputGroup className="mb-3">
            <Form.Control
              placeholder="Soru aciklamasi"
              value={modal.data?.questionDescription}
              onChange={(e) =>
                setModalShow((modal) => ({
                  ...modal,
                  data: { ...modal.data, questionDescription: e.target.value },
                }))
              }
            />
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={UpdateScopeQuestion}>
            Güncelle
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
