import Modal from "react-bootstrap/Modal";
import { Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { UpsertLeanParameters } from "../../../Store/Thunk";
import RangeParameter from "./RangeParameter";
import { useTranslation } from "react-i18next";
import { UpdateLeanModalState, UpdateLeanParameters } from "../../../Store";

export default function LeanModal() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isVisible = useSelector((state) => state.inventory.leanModalState.isVisible);
  const inventory = useSelector((state) => state.inventory.leanModalState.inventory);

  const updateInventoryLeanParameter = (property, value) => {
    dispatch(
      UpdateLeanParameters({
        ...inventory,
        leanParameters: {
          ...inventory.leanParameters,
          [property]: value,
        },
      })
    );
  };

  const HandlePostUpsertLeanParameters = async (inventory) => {
    const postObj = CreatePostObj(inventory);
    await dispatch(UpsertLeanParameters(postObj));
  };

  const CreatePostObj = (inventory) => {
    var postObj = {
      InventoryId: inventory.id,
      leanParametersId: inventory.leanParameters?.id,
      HighRange: inventory.leanParameters.highRange,
      MidRange: inventory.leanParameters.midRange,
      LowRange: inventory.leanParameters.lowRange,
      EdgeRange: inventory.leanParameters.edgeRange,
      HighRate: inventory.leanParameters.highRate,
      MidRate: inventory.leanParameters.midRate,
      LowRate: inventory.leanParameters.lowRate,
      EdgeRate: inventory.leanParameters.edgeRate,
    };
    return postObj;
  };

  const parameterNames = [
    { prop: "highRange", label: t("highRange") },
    { prop: "midRange", label: t("midRange") },
    { prop: "lowRange", label: t("lowRange") },
    { prop: "edgeRange", label: t("spacingOfTips") },
    { prop: "highRate", label: t("highFrequency") },
    { prop: "midRate", label: t("mediumFrequency") },
    { prop: "lowRate", label: t("lowFrequency") },
    { prop: "edgeRate", label: t("frequencyOfLeads") },
  ];

  const closeModal = () => {
    dispatch(UpdateLeanModalState({ isVisible: false, inventory: null }));
  };

  return (
    <Modal show={isVisible} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>
          {inventory?.id} {inventory?.name} {t("predispositionParameters")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          {parameterNames.map(({ prop, label }) => (
            <RangeParameter
              key={prop}
              label={label}
              value={inventory?.leanParameters?.[prop]}
              onChange={(e) => updateInventoryLeanParameter(prop, e.target.value)}
            />
          ))}
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => HandlePostUpsertLeanParameters(inventory)}>
          {t("save")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
