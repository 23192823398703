import React, { useEffect } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Card from "react-bootstrap/Card";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { createApplication, getTests } from "../Store/Thunk";
import { updateTest } from "../Store";

import TestEntityList from "../../TestEntityList";
import { ScoringScale } from "./ScoringScale";
import ScrollableCard from "../../../../SharedComponents/ScrollableCard";

export default function Test() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const test = useSelector((state) => state.test.test);

  useEffect(() => {
    dispatch(getTests());
  }, [dispatch]);

  useEffect(() => {
    if (test.cleanScoringComp) {
      dispatch(updateTest({ key: "cleanScoringComp", value: false }));
    }
  });

  const updateTestProperty = (key, value) => {
    dispatch(updateTest({ key, value }));
  };

  const createTest = () => {
    dispatch(createApplication(test));
  };

  const isButtonDisabled = () => {
    if (test.Name && test.Duration && test.scales?.length) return false;
    return true;
  };

  return (
    <div>
      <Card body className="mb-3">
        <Row>
          <Col xs={12} md={9}>
            <h6>{t("testName")}</h6>
            <InputGroup className="mb-3">
              <Form.Control
                placeholder={t("enterTestName")}
                value={test.Name}
                onChange={(e) => updateTestProperty("Name", e.target.value)}
              />
            </InputGroup>
          </Col>

          <Col xs={12} md={1}>
            <h6>{t("duration")}</h6>
            <InputGroup className="mb-3">
              <Form.Control
                placeholder={t("enterDuration")}
                type="number"
                value={test.Duration}
                onChange={(e) => updateTestProperty("Duration", e.target.value)}
              />
            </InputGroup>
          </Col>

          <Col xs={12} md={2} className="mb-3">
            <Button
              style={{ height: "100%", width: "100%" }}
              variant="primary"
              onClick={createTest}
              disabled={isButtonDisabled()}
            >
              {t("create")}
            </Button>
          </Col>
        </Row>
        <ScoringScale
          HandleAddScoreScale={(data) => updateTestProperty("scales", data)}
          scale={test?.scales}
          clean={test.cleanScoringComp}
        />
      </Card>

      <h5>{t("availableTests")}</h5>
      <ScrollableCard>
        <TestEntityList isTest={true} />
      </ScrollableCard>
    </div>
  );
}
