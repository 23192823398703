import { createSlice } from "@reduxjs/toolkit";
import { getPositionById } from "./Thunk";

export const position = createSlice({
  name: "position",
  initialState: {
    id: "",
    name: "",
    candidates: [],
    createdAt: "",
    createdBy: "",
    description: "",
    isLoading: false,
  },
  reducers: {
    UpdateCompanyId(state, action) {
      state.companyId = action.payload.companyId;
    },

    ChangeIsUpdateOperation(state, action) {
      state.isUpdateOperation = action.payload.isUpdateOperation;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPositionById.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getPositionById.fulfilled, (state, action) => {
      state.id = action.payload.id;
      state.name = action.payload.name;
      state.createdAt = action.payload.createdAt;
      state.createdBy = action.payload.createdBy;
      state.description = action.payload.description;
      state.candidates = action.payload.candidates;

      state.isLoading = false;
    });
  },
});

export const { SetUpdatePositionModalData, ShowUpdatePositionModal } = position.actions;

export default position.reducer;
