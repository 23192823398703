import { createSlice } from "@reduxjs/toolkit";

export const adminData = createSlice({
  name: "adminData",
  initialState: {
    companyUser: {},
  },
  reducers: {
    SetCompanyUser(state, action) {
      state.companyUser = action.payload.companyUser;
    },
  },
});

export const { SetCompanyUser } = adminData.actions;

export default adminData.reducer;
