export const api = {
  upsertCredit: "credit/UpsertCredit",
  getB2bAssessments: "assessment/GetB2bAssessments",
  getCreditsByCompanyId: "credit/GetCreditsByCompanyId",
  getUploadRecordsByCreditId: "credit/GetUploadRecordsByCreditId",
};

export default class creditsAPI {
  constructor(httpsClient, cache) {
    this.httpsClient = httpsClient;
    this.cache = cache;
  }

  async upsertCredit(data, token) {
    return this.get(api.upsertCredit, data, token);
  }

  async getB2bAssessments(data, token) {
    return this.get(api.getB2bAssessments, data, token);
  }

  async getCreditsByCompanyId(data, token) {
    return this.get(api.getCreditsByCompanyId, data, token);
  }

  async getUploadRecordsByCreditId(data, token) {
    return this.get(api.getUploadRecordsByCreditId, data, token);
  }

  async get(url, data, token, eAlert, sAlert) {
    return await this.httpsClient.get(url, data, token, eAlert, sAlert);
  }

  async post(url, data, token, eAlert, sAlert) {
    this.cache.remove("tests");

    return await this.httpsClient.post(url, data, token, eAlert, sAlert);
  }
}
