import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import PositionCandidatesList from "./PositionCandidatesList";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Overlay from "../../../../SharedComponents/Overlay";
import { getPositionById } from "../Store/Thunk";
import Col from "react-bootstrap/Col";
import { ParseDate } from "../../../../Helpers/DateHelper";
import UpsertPositionModal from "../../CreatePosition/Components/UpsertPositionModal";
import {
  ChangeIsUpdateOperation,
  ShowUpsertPositionModal,
  UpdateUpsertPositionModalData,
} from "../../CreatePosition/Store";
import Button from "react-bootstrap/Button";
import { deletePosition } from "../../CreatePosition/Store/Thunk";
import CustomModal from "../../../../SharedComponents/CustomModal";
import AdminBreadCrumb from "../../../../Components/Dashboard/AdminBreadCrumb";
import IconSvg from "../../../../App/AppMenu/IconSvg";

export default function Position() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => state.position.isLoading);
  const position = useSelector((state) => state.position);
  const positionList = useSelector((state) => state.createPosition.positionList);
  const { state } = useLocation();
  const positionId = state?.positionId;

  useEffect(() => {
    dispatch(getPositionById({ id: positionId }));
  }, [positionList]);

  const HandleUpdateClick = (position) => {
    dispatch(UpdateUpsertPositionModalData({ position: position }));
    dispatch(ChangeIsUpdateOperation({ isUpdateOperation: true }));
    dispatch(ShowUpsertPositionModal({ upsertPositionModalIsShown: true }));
  };

  const DeletePosition = (id) => {
    dispatch(deletePosition({ id: id }));
    navigate("/createPosition");
  };

  return (
    <div>
      <CustomModal></CustomModal>

      {isLoading && <Overlay />}

      <Row className={"mx-0"}>
        <Col>
          <div className={"text-14 text-color-gray-light fw-medium mb-3"}>POZİSYON</div>
          <AdminBreadCrumb
            pageName={t("createPositionBreadCrumbLabel")}
            backPageName={t("positionBreadCrumbLabel")}
            backUrl="/createPosition"
          >
            <Row>
              <Col>
                <Card className={"breadcrumb-card h-100"}>
                  <Row className={"h-100 mx-0"}>
                    <Col className={"align-content-center text-20 text-color-gray-dark fw-medium"}>
                      {position?.name} POZİSYONU
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col xs={"auto"}>
                <Button
                  className={"button secondary"}
                  size="sm"
                  style={{ height: "46px", width: "46px", padding: "4px", borderRadius: "10px", borderWidth: "1px" }}
                  onClick={() => {
                    DeletePosition(position.id);
                  }}
                >
                  <IconSvg icon="edit" fill={"#0077B6"} />
                </Button>
              </Col>
              <Col xs={"auto"}>
                <Button
                  className={"button secondary"}
                  size="sm"
                  style={{ height: "46px", width: "46px", padding: "4px", borderRadius: "10px", borderWidth: "1px" }}
                  onClick={() => {
                    HandleUpdateClick(position);
                  }}
                >
                  <IconSvg icon="delete" fill={"#0077B6"} />
                </Button>
              </Col>
            </Row>
          </AdminBreadCrumb>
        </Col>
      </Row>
      <Row className={"mx-0"}>
        <Col>
          <div className={"text-20 text-color-gray-dark"}>{position.description}</div>
          {/*TODO: Burada scrollableDiv olacak mı ? */}
        </Col>
        <Col xs={"auto"}>
          <h6>
            {ParseDate(position.createdAt).date} {ParseDate(position.createdAt).time}
          </h6>
          <h6>By: {position?.createdBy}</h6>
        </Col>
      </Row>
      
      <Row className={"mx-0"}>
        <Col>
          <div className={"text-14 text-color-gray-light fw-medium mb-3"}>POZİSYONA ATANAN ADAYLAR</div>
        </Col>
      </Row>

      <Row className={"mx-0"}>
        <Col>
          <Card className={"default-list"}>
            <PositionCandidatesList candidatesList={position?.candidates}></PositionCandidatesList>
          </Card>
        </Col>
      </Row>

      <UpsertPositionModal></UpsertPositionModal>
    </div>
  );
}
