import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import IconSvg from "../../../../App/AppMenu/IconSvg";
import { ChangeIsUpdateOperation, SetUpsertModalCandidate, ShowUpsertCandidateModal } from "../Store";

const CreateCandidateListRow = ({ candidate }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const positionList = useSelector((state) => state.createPosition.positionList);
  const [carouselItems, setCarouselItems] = useState([]);

  useEffect(() => {
    // assessments
    setCarouselItems(
      groupIntoBundles(
        [
          "asd",
          "asd",
          "asd",
          "asd",
          "asd",
          "asd",
          "asd",
          "asd",
          "asd",
          "asd",
          "asd",
          "asd",
          "asd",
          "asd",
          "asd",
          "asd",
          "asd",
          "asd",
          "asd",
          "asd",
          "asd",
          "asd",
        ],
        3
      )
    );
  }, []);

  const FindPositionNameByPositionId = (positionId) => {
    return positionList.find((position) => position.id === positionId).name;
  };

  const groupIntoBundles = (items, bundleSize) => {
    const result = [];
    let bundle = [];
    items.forEach((item, index) => {
      bundle.push(item);
      if ((index + 1) % bundleSize === 0 || index === items.length - 1) {
        result.push(bundle);
        bundle = [];
      }
    });
    return result;
  };

  const handleUpdateCandidate = () => {
    dispatch(SetUpsertModalCandidate(candidate));

    dispatch(ShowUpsertCandidateModal({ upsertCandidateModalIsShown: true }));
    dispatch(ChangeIsUpdateOperation({ isUpdateOperation: true }));
  };

  return (
    <Row className={"h-100"}>
      <Col xs={"auto"} className="align-content-center ms-5">
        <Row className={"justify-content-center"}>
          <div
            style={{
              border: "1px solid #E7ECEF",
              width: "80px",
              height: "80px",
              borderRadius: "50%",
              alignContent: "center",
            }}
          >
            <Row className={"justify-content-center"}>
              <IconSvg icon={"candidate"} />
            </Row>
          </div>
        </Row>
      </Col>
      <Col
        xs={3}
        className={"position-relative align-content-center ms-5 my-3 border-end"}
        style={{ borderColor: "#E7ECEF" }}
      >
        <Row>
          <Col
            xs={"auto"}
            className={"text-20 text-color-accent fw-semibold"}
            style={{
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/candidate", { state: { candidateId: candidate.candidateId } });
            }}
          >
            {candidate.candidateName}
          </Col>
        </Row>
        <Row className={"text-16 text-color-gray-light fw-medium"}>
          <Col xs={"auto"}>{candidate.email}</Col>
        </Row>
        <Row className={"text-16 text-color-gray-light fw-medium"}>
          <Col xs={"auto"}>{FindPositionNameByPositionId(candidate.positionId)}</Col>
        </Row>

        <div
          className="hover-bg-darken p-1 absolute-top-right"
          onClick={() => {
            handleUpdateCandidate();
          }}
        >
          <IconSvg icon="edit" />
        </div>
      </Col>
      <Col className={"my-3 border-end"} style={{ borderColor: "#E7ECEF" }}>
        {/*<Carousel
            className={"custom-carousel"}
            prevIcon={<IconSvg icon={"download"} />}
            nextIcon={<IconSvg icon={"download"} />}
          >
            {carouselItems &&
              carouselItems.map((carouselItem, index) => {
                return (
                  <Carousel.Item className={"bg-success p-4"} key={index}>
                    <Row>
                      {carouselItem.map((companyCredit, index) => {
                        return (
                          <Col xs={12} lg={4} key={index}>
                            <div className={"bg-success"}> asdas</div>
                          </Col>
                        );
                      })}
                    </Row>
                  </Carousel.Item>
                );
              })}
          </Carousel>*/}
      </Col>
      <Col xs={"auto"} className={"align-content-center me-5 ms-4"}>
        <Row className={"justify-content-between"}>
          <Col xs={"auto"} className={"align-content-center"}>
            <Row className={"justify-content-center"}>
              <Button className={"button secondary"}>Değerleme Gönder</Button>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default CreateCandidateListRow;
