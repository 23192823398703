import { createSlice } from "@reduxjs/toolkit";
import { createPosition_, deletePosition, getPositionsByCompanyId, updatePosition } from "./Thunk";

export const createPosition = createSlice({
  name: "createPosition",
  initialState: {
    id: "",
    name: "",
    positionList: [],
    upsertPositionModalData: {},
    upsertPositionModalIsShown: false,
    isUpdateOperation: false,
    isLoading: false,
  },
  reducers: {
    UpdateUpsertPositionModalData(state, action) {
      state.upsertPositionModalData = action.payload.position;
    },
    ShowUpsertPositionModal(state, action) {
      state.upsertPositionModalIsShown = action.payload.upsertPositionModalIsShown;
    },
    ChangeIsUpdateOperation(state, action) {
      state.isUpdateOperation = action.payload.isUpdateOperation;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createPosition_.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createPosition_.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(updatePosition.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updatePosition.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(deletePosition.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deletePosition.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(getPositionsByCompanyId.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getPositionsByCompanyId.fulfilled, (state, action) => {
      state.positionList = action.payload;
      state.isLoading = false;
    });
  },
});

export const { UpdateUpsertPositionModalData, UpdateCompanyId, ChangeIsUpdateOperation, ShowUpsertPositionModal } =
  createPosition.actions;

export default createPosition.reducer;
