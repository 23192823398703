import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import { Button, ButtonGroup, Col, Row } from "react-bootstrap";
import { createReportScheme, deleteReportScheme, getAllReportItems, updateReportScheme } from "../Store/Thunk";
import { setRole, updateReportSchemeState } from "../Store";
import BreadCrumb from "../../../../Components/Dashboard/BreadCrumb";
import BasicGraphSummaryEntity from "./Entities/BasicGraphSummaryEntity";
import BasicGraphDetailEntity from "./Entities/BasicGraphDetailEntity";
import MonoTextuaryEntity from "./Entities/MonoTextuaryEntity";
import DualTextuaryEntity from "./Entities/DualTextuaryEntity";
import Overlay from "../../../../SharedComponents/Overlay";
import SchemeElementModal from "./SchemeElementModal";
import EmptySpace from "./HtmlElements/EmptySpace";
import MonoSpace from "./HtmlElements/MonoSpace";
import DualSpace from "./HtmlElements/DualSpace";
import ImageSpace from "./HtmlElements/ImageSpace";
import ScrollableCard from "../../../../SharedComponents/ScrollableCard";
import HeightCalculator from "./HeightCalculator";
import Dropdown from "react-bootstrap/Dropdown";
import EntityInfoModal from "./Entities/EntityInfoModal/EntityInfoModal";
import TestScaleEntity from "./ApplicationElements/TestScaleEntity";
import AnsweringTendencyEntity from "./ApplicationElements/AnsweringTendencyEntity";
import MassFilterButtonGroup from "./MassFilterButtonGroup";
import { applicationTypeEnum, GetKeyByValue } from "../../../../Helpers/EnumHelper";

export default function ReportScheme() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const reportSchemeState = useSelector((state) => state.reportScheme);
  const entityModalInfo = useSelector((state) => state.reportScheme.entityModalInfo);
  const role = useSelector((state) => state.reportScheme.role);
  const [schemeElementModalData, setSchemeElementModalData] = useState({
    show: false,
    type: null,
  });

  //yeni eklenecek dropdown ile hedef array secilecek

  const components = {
    BasicGraphSummaryEntity,
    BasicGraphDetailEntity,
    MonoTextuaryEntity,
    DualTextuaryEntity,
    DualSpace,
    MonoSpace,
    EmptySpace,
    ImageSpace,
    TestScaleEntity,
    AnsweringTendencyEntity,
  };

  useEffect(() => {
    dispatch(updateReportSchemeState({ testId: state.id }));
    dispatch(getAllReportItems({ id: state.id, applicationType: state.applicationType }));
  }, [role]);

  const Paginate = (scheme) => {
    let Page = [];
    let Pages = [];
    let maxPageHeight = 900; //TODO: Change pageheight accordingly
    let currentPageHeight = 0;

    scheme.forEach((reportItem, index) => {
      //if under maxPageHeight   push into Page[]
      if (currentPageHeight + Number(reportItem.height) < maxPageHeight) {
        Page.push(reportItem);
        currentPageHeight = currentPageHeight + Number(reportItem.height);
        if (index === scheme.length - 1) {
          //if the Page is not filled, and it's the end of the scheme; bundle & push anyway.
          currentPageHeight = 0;
          Pages.push(Page);
          Page = [];
        }
      }
      // bundle the Page array and push it into Pages[]
      else {
        currentPageHeight = 0;
        Pages.push(Page);
        Page = [];
      }
    });
    return Pages;
  };

  const UpsertScheme = (newList) => {
    var postObj = {
      applicationId: reportSchemeState.inventory.id,
      applicationType: state.applicationType,
      role: role,
      WrapUpJson: JSON.stringify(newList ? newList : reportSchemeState.visibleReportItemEntityList),
      PdfJson: JSON.stringify(Paginate(newList ? newList : reportSchemeState.visibleReportItemEntityList)),
    };

    if (reportSchemeState?.inventory?.reportScheme) {
      postObj.id = reportSchemeState.inventory.reportScheme.id;
      dispatch(updateReportScheme(postObj));
    } else {
      dispatch(createReportScheme(postObj));
    }
  };

  const DeleteReportScheme = () => {
    dispatch(deleteReportScheme({ id: reportSchemeState?.inventory?.reportScheme.id }));
  };

  const CreateDynamicComponent = (entity) => {
    const SpecificComponent = components[entity.componentName];

    return (
      <SpecificComponent key={Math.random().toString(16).slice(2)} entity={entity}>
        {entity.height === undefined && (
          <div style={{ position: "absolute", left: "-999999px" }}>
            <HeightCalculator
              entity={entity}
              type={GetKeyByValue(state.applicationType, applicationTypeEnum).toLowerCase()}
            />
          </div>
        )}
      </SpecificComponent>
    );
  };

  const CreateDynamicComponentWithOutHeightCalculation = (entity) => {
    const SpecificComponent = components[entity.componentName];

    return <SpecificComponent key={Math.random()} entity={entity} />;
  };

  return (
    <div>
      {reportSchemeState.isLoading && <Overlay />}
      <BreadCrumb
        pageName={t("Şema")}
        backPageName={state.isTest ? t("tests") : t("inventories")}
        backUrl={state.isTest ? "/test" : "/inventory"}
      >
        <Row className={"justify-content-between"}>
          <Col xs="12" md="5">
            <Row xs="auto">
              <Col xs={"auto"}>
                <h3> {reportSchemeState?.inventory?.id} </h3>
              </Col>
              <Col xs={"auto"}>
                <h3> {reportSchemeState?.inventory?.name} </h3>
              </Col>
            </Row>
          </Col>
          <Col xs="12" md="auto">
            <Dropdown drop={"down-centered"}>
              <Dropdown.Toggle size={"sm"}>{role.charAt(0).toUpperCase() + role.slice(1)}</Dropdown.Toggle>{" "}
              {/*upper case method*/}
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    dispatch(setRole({ role: "admin" }));
                  }}
                >
                  Admin
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    dispatch(setRole({ role: "user" }));
                  }}
                >
                  User
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col xs="12" md="auto">
            <ButtonGroup>
              <Button size={"sm"} onClick={() => setSchemeElementModalData({ show: true, type: "mono" })}>
                Tek Bölüm
              </Button>
              <Button size={"sm"} onClick={() => setSchemeElementModalData({ show: true, type: "dual" })}>
                Çift Bölüm
              </Button>
              <Button size={"sm"} onClick={() => setSchemeElementModalData({ show: true, type: "space" })}>
                Boşluk
              </Button>
              <Button size={"sm"} onClick={() => setSchemeElementModalData({ show: true, type: "image" })}>
                Görsel
              </Button>
            </ButtonGroup>
          </Col>
          <Col xs="12" md="auto">
            <Row xs="auto" className="justify-content-md-end">
              <Col>
                <Button size={"sm"} onClick={() => UpsertScheme()}>
                  {reportSchemeState?.inventory?.reportScheme ? "Güncelle" : "Kaydet"}
                </Button>
              </Col>
              {reportSchemeState?.inventory?.reportScheme && (
                <Col>
                  <Button size={"sm"} variant="danger" onClick={DeleteReportScheme}>
                    Kaydı Sil
                  </Button>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </BreadCrumb>

      <Row>
        <Col>
          <h1 className={"mb-0"}> Rapor Elemanları </h1>
        </Col>
        <Col xs={"auto"}>
          <MassFilterButtonGroup />
        </Col>
      </Row>

      <ScrollableCard>
        {reportSchemeState.visibleReportItemEntityList && //truelari listele
          reportSchemeState.visibleReportItemEntityList.map((entity) => {
            return CreateDynamicComponent(entity);
          })}

        <h1> Kaldırılanlar </h1>
        {reportSchemeState.nonVisibleReportItemEntityList && //falseları listele
          reportSchemeState.nonVisibleReportItemEntityList.map((entity) => {
            return (
              <div className={"opacity-50"} key={Math.random()}>
                {CreateDynamicComponentWithOutHeightCalculation(entity)}
              </div>
            );
          })}
      </ScrollableCard>

      <SchemeElementModal
        schemeElementModalData={schemeElementModalData}
        onHide={() => setSchemeElementModalData({ show: false, type: null })}
        UpdateScheme={UpsertScheme}
      />
      {entityModalInfo.isShown && (
        <EntityInfoModal isShown={entityModalInfo.isShown} entity={entityModalInfo.entity}></EntityInfoModal>
      )}
    </div>
  );
}
