import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Overlay from "../../../../SharedComponents/Overlay";
import CreateCandidatesList from "./CreateCandidatesList";
import { ChangeIsUpdateOperation, ShowUpsertCandidateModal } from "../Store";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { getCandidatesByCompanyId } from "../Store/Thunk";
import { getPositionsByCompanyId } from "../../CreatePosition/Store/Thunk";
import UpsertCandidateModal from "./UpsertCandidateModal";
import ScrollableCard from "../../../../SharedComponents/ScrollableCard";
import IconSvg from "../../../../App/AppMenu/IconSvg";

export default function CreateCandidate() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.createCandidate.isLoading);
  const candidateList = useSelector((state) => state.createCandidate.candidateList);
  const positionList = useSelector((state) => state.createPosition.positionList);
  const companyId = useSelector((state) => state.adminData.companyUser.company.companyId);

  useEffect(() => {
    dispatch(getPositionsByCompanyId({ id: companyId }));
    dispatch(getCandidatesByCompanyId({ companyId: companyId }));
  }, [companyId]);

  const CreateCandidate = () => {
    dispatch(ShowUpsertCandidateModal({ upsertCandidateModalIsShown: true }));
    dispatch(ChangeIsUpdateOperation({ isUpdateOperation: false }));
  };

  return (
    <div>
      <Form>
        <Row className={"justify-content-between mx-0 mb-3"}>
          <Col>
            {/*<FloatingLabel controlId={"floatingCandidateSearch"} label={t("searchCandidateFloatingLabel")}>*/}
            <Form.Control placeholder={t("searchCandidateFloatingLabel")} className={"h-100"}></Form.Control>
          </Col>
          <Col xs={"auto"}>
            <Button
              className={"button secondary"}
              size={"sm"}
              onClick={() => {
                CreateCandidate();
              }}
            >
              <Row>
                <Col xs={"auto"} className={"align-content-center"}>
                  <IconSvg icon={"plusV2"} height={22} width={22}></IconSvg>
                </Col>
                <Col xs={"auto"} className={"text-20 fw-medium"}>
                  {t("createNewPositionButtonLabel")}
                </Col>
              </Row>
            </Button>
          </Col>
        </Row>
      </Form>
      {isLoading && <Overlay />}
      <ScrollableCard bottom={20}>
        <CreateCandidatesList candidatesList={candidateList}></CreateCandidatesList>
      </ScrollableCard>
      <UpsertCandidateModal positionList={positionList}></UpsertCandidateModal>
    </div>
  );
}
