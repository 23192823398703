import { createSlice } from "@reduxjs/toolkit";
import { createCandidate_, deleteCandidate, getCandidatesByCompanyId, updateCandidate } from "./Thunk";

export const createCandidate = createSlice({
  name: "createCandidate",
  initialState: {
    id: "",
    candidate: {},
    candidateList: [],
    upsertCandidateModalIsShown: false,

    isLoading: false,
  },
  reducers: {
    UpdateCompanyId(state, action) {
      state.companyId = action.payload.companyId;
    },
    UpdateUpsertCandidateModalData(state, action) {
      state.candidate = action.payload;
    },
    ShowUpsertCandidateModal(state, action) {
      state.upsertCandidateModalIsShown = action.payload.upsertCandidateModalIsShown;
    },
    SetUpsertModalCandidate(state, action) {
      state.candidate = action.payload;
    },
    ChangeIsUpdateOperation(state, action) {
      state.isUpdateOperation = action.payload.isUpdateOperation;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createCandidate_.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createCandidate_.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(updateCandidate.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateCandidate.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(deleteCandidate.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(deleteCandidate.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(getCandidatesByCompanyId.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getCandidatesByCompanyId.fulfilled, (state, action) => {
      state.candidateList = action.payload;
      state.isLoading = false;
    });
  },
});

export const {
  SetUpsertModalCandidate,
  UpdateUpsertCandidateModalData,
  ShowUpsertCandidateModal,
  ChangeIsUpdateOperation,
} = createCandidate.actions;

export default createCandidate.reducer;
