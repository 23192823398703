import { createAsyncThunk } from "@reduxjs/toolkit";

import HttpsClient from "../../../../Services/HttpsClient";
import ApiHelper from "../../../../Services/ApiHelper";
import ApplicationInfoAPI, { ApplicationInfoApiUrls } from "../API/ApplicationInfoAPI";

const httpsClient = new HttpsClient();
const apiHelper = new ApiHelper(httpsClient);
const applicationInfoAPI = new ApplicationInfoAPI(apiHelper);

export const deleteInfoImage = createAsyncThunk(
  ApplicationInfoApiUrls.deleteInfoImage,
  async (payload, { getState, dispatch }) => {
    const state = getState();
    const token = state.auth.user.token;

    const response = await applicationInfoAPI.deleteInfoImage(payload, token);

    return response;
  }
);
