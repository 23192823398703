import React from "react";
import Row from "react-bootstrap/Row";
import { useDispatch } from "react-redux";
import Col from "react-bootstrap/Col";
import CommentList from "./CommentList";

const Comment = () => {
  const dispatch = useDispatch();

  return (
    <div>
      <Row className={"justify-content-between mt-3 mb-2"}>
        <Col xs={"auto"} className={"text-14 text-color-gray-light fw-medium"}>
          Yorumlar
        </Col>
      </Row>
      <CommentList></CommentList>
    </div>
  );
};
export default Comment;
