import React from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

const CustomModal = () => {
  return (
    <Modal show={false} contentClassName="custom-modal-content" dialogClassName={"custom-modal-dialog"}>
      <div className={"bordered"}>
        <Modal.Header closeButton className="p-0 border-bottom-0"></Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Label column={"sm"} className={"text-20 text-color-gray-light fw-medium"}>
              Name
            </Form.Label>
            <Form.Control className={"default-text-input"} style={{ height: "50px" }} placeholder="oasp"></Form.Control>
          </Form>
        </Modal.Body>
      </div>
    </Modal>
  );
};
export default CustomModal;
