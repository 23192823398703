import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Card from "react-bootstrap/Card";

import { createSurvey, getSurveys, updateSurvey } from "../Store/Thunk";
import Overlay from "../../../../SharedComponents/Overlay";
import SurveyList from "./SurveyList";
import { UpdateName, UpdateSurveyState } from "../Store";
import ScrollableCard from "../../../../SharedComponents/ScrollableCard";

export default function Survey() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.survey.isLoading);
  const name = useSelector((state) => state.survey.name);
  const surveyId = useSelector((state) => state.survey.id);
  const isUpdateOperation = useSelector((state) => state.survey.isUpdateOperation);

  useEffect(() => {
    dispatch(getSurveys());
  }, []);

  const submitForm = (event) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);

    if (isUpdateOperation)
      dispatch(
        updateSurvey({
          name: formData.get("name"),
          id: surveyId,
        })
      );
    else
      dispatch(
        createSurvey({
          name: formData.get("name"),
        })
      );

    ClearInputs();

    event.currentTarget.reset();
  };

  const ClearInputs = () => {
    dispatch(
      UpdateSurveyState({
        name: "",
        id: "",
      })
    );
  };

  return (
    <div>
      {isLoading && <Overlay />}
      <Card body className="mb-3">
        <Form onSubmit={submitForm}>
          <Row>
            <Col xs={12} md={10}>
              <h6>{isUpdateOperation ? surveyId : t("surveyName")}</h6>
              <InputGroup className="mb-3">
                <Form.Control
                  required
                  name="name"
                  placeholder={t("enterSurveyName")}
                  value={name}
                  onChange={(e) => dispatch(UpdateName(e.target.value))}
                />
              </InputGroup>
            </Col>
            <Col xs={12} md={2} style={{ marginBottom: "0px !important" }}>
              <Row style={{ height: !isUpdateOperation && "100%", margin: 0 }}>
                <Button
                  className="mb-1"
                  type="submit"
                  style={{ width: "100%", height: "100%" }}
                  variant="primary"
                  disabled={!name}
                >
                  {isUpdateOperation ? t("update") : t("create")}
                </Button>
              </Row>
              {isUpdateOperation && (
                <Row style={{ margin: 0 }}>
                  <Button style={{ width: "100%", height: "100%" }} variant="secondary" onClick={ClearInputs}>
                    {t("decline")}
                  </Button>
                </Row>
              )}
            </Col>
          </Row>
        </Form>
      </Card>
      <h5>{t("currentSurveys")}</h5>
      <ScrollableCard>
        <SurveyList />
      </ScrollableCard>
    </div>
  );
}
