export const ProjectApiUrls = {
  createCandidate: "candidate/CreateCandidate",
  updateCandidate: "candidate/UpdateCandidate",
  deleteCandidate: "candidate/DeleteCandidate",
  getCandidatesByAssessmentId: "candidate/GetCandidatesByAssessmentId",
};

export default class projectAPI {
  constructor(httpsClient, cache) {
    this.httpsClient = httpsClient;
    this.cache = cache;
  }

  async createCandidate(data, token) {
    return this.post(ProjectApiUrls.createCandidate, data, token);
  }

  async updateCandidate(data, token) {
    return this.post(ProjectApiUrls.updateCandidate, data, token);
  }

  async deleteCandidate(data, token) {
    return this.get(ProjectApiUrls.deleteCandidate, data, token);
  }

  async getCandidatesByAssessmentId(data, token) {
    return this.get(ProjectApiUrls.getCandidatesByAssessmentId, data, token);
  }

  async get(url, data, token, eAlert, sAlert) {
    return await this.httpsClient.get(url, data, token, eAlert, sAlert);
  }

  async post(url, data, token, eAlert, sAlert) {
    this.cache.remove("tests");

    return await this.httpsClient.post(url, data, token, eAlert, sAlert);
  }
}
