export const AdminDashApiUrls = {
  getCompanyCredits: "dash/GetCompanyCredits",
};

export default class adminDashAPI {
  constructor(httpsClient, cache) {
    this.httpsClient = httpsClient;
    this.cache = cache;
  }

  async getCompanyCredits(data, token) {
    return this.get(AdminDashApiUrls.getCompanyCredits, data, token);
  }

  async get(url, data, token, eAlert, sAlert) {
    return await this.httpsClient.get(url, data, token, eAlert, sAlert);
  }

  async post(url, data, token, eAlert, sAlert) {
    this.cache.remove("tests");

    return await this.httpsClient.post(url, data, token, eAlert, sAlert);
  }
}
