export const ApplicationInfoApiUrls = {
  deleteInfoImage: "application/deleteInfoImage",
};

export default class ApplicationInfoAPI {
  constructor(httpsClient) {
    this.httpsClient = httpsClient;
  }

  async deleteInfoImage(data, token) {
    return this.get(ApplicationInfoApiUrls.deleteInfoImage, data, token);
  }

  async get(url, data, token) {
    const response = await this.httpsClient.get(url, data, token);
    return response;
  }

  async post(url, data, token) {
    await this.httpsClient.post(url, data, token);
  }
}
