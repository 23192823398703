import React from "react";
import { useNavigate } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import IconSvg from "../../App/AppMenu/IconSvg";
import { Button } from "react-bootstrap";

const AdminBreadCrumb = ({ children, pageName, backPageName, backUrl, backState }) => {
  const navigate = useNavigate();

  return (
    <Row className="mb-3">
      <Col xs={"auto"}>
        <Row xs="auto" className={"h-100"}>
          <Col>
            <Button
              onClick={() => navigate(backUrl, backState)}
              className={"button secondary"}
              size="sm"
              style={{ height: "46px", width: "46px", padding: "4px", borderRadius: "10px", borderWidth: "1px" }}
            >
              <IconSvg icon="arrow-left" />
            </Button>
          </Col>
        </Row>
      </Col>
      <Col>{children}</Col>
    </Row>
  );
};

export default AdminBreadCrumb;
