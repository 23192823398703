import axios from "axios";

export const baseURL = "https://api.cus-pro.com/";
// export const baseURL = "https://api2.cus-pro.com/";
// export const baseURL = "https://localhost:44359/";

export const BaseApiUrl = baseURL + "api/";

export const postAsync = async (url, data, afterCallback, token) => {
  makeRequest("post", BaseApiUrl + url, null, data, afterCallback, token);
};

export const getAsync = async (url, param, afterCallback, token) => {
  makeRequest("get", BaseApiUrl + url, param, null, afterCallback, token);
};

const makeRequest = async (method, url, param, data, afterCallback, token, sAlert, eAlert) => {
  let response = null;
  try {
    response = await axios({
      method,
      url,
      data: data,
      params: param,
      // "Content-Type": "multipart/form-data",
      // enctype: "multipart/form-data",
      // responseType: "json",
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        "Access-Control-Allow-Headers": "Content-Type",
      },
    });

    const isDebug = window.isDebug || false;

    // Custom message alerts based on response status and debug mode
    if (isDebug || (response.data.statusCode === 0 && eAlert)) {
      alert(
        `BusinessStatus => ${response.data.statusCode}\n` +
          `BusinessMessage => ${response.data.message}\n` +
          `URL => ${url}\n` +
          `HTTP Status => ${response.status}`
      );
    } else if (response.data.statusCode === 1 && sAlert) {
      alert(`${response.data.message}\nURL => ${url}`);
    }

    // Log debug info to the console for additional traceability
    if (isDebug) {
      console.log("Debug Info:", {
        url,
        responseStatus: response.status,
        businessStatus: response.data.statusCode,
        businessMessage: response.data.message,
        responseData: response.data,
      });
    } //TODO SET This TO sAlert

    if (afterCallback) afterCallback(response);
  } catch (err) {
    if (window.isDebug) {
      console.log(err);
      alert(err.response?.status + "- Hata --> " + url + " // " + err.name + " - " + err.message);
    }

    if (err.response?.status === 401) {
      localStorage.clear();
      window.location.reload(true);
    }
    // if (afterCallback) afterCallback(err);
  }
};

const xmlPostFormData = async (url, formData, token, callback) => {
  const request = new XMLHttpRequest();
  request.open("POST", BaseApiUrl + url);
  request.onreadystatechange = function () {
    if (this.readyState === 4 && this.status === 200) {
      if (callback) callback(this);
    }
  };
  request.setRequestHeader("Authorization", `Bearer ${token}`);
  request.send(formData);
};

const ApiHelper = {
  postAsync,
  getAsync,
  xmlPostFormData,
  baseURL,
};

export default ApiHelper;

export const HandleDebugMode = () => {};
