export const CreatePositionApiUrls = {
  createPosition: "position/CreatePosition",
  updatePosition: "position/UpdatePosition",
  deletePosition: "position/DeletePosition",
  getPositionsByCompanyId: "position/GetPositionsByCompanyId",
};

export default class createPositionAPI {
  constructor(httpsClient, cache) {
    this.httpsClient = httpsClient;
    this.cache = cache;
  }

  async createPosition(data, token) {
    return this.post(CreatePositionApiUrls.createPosition, data, token);
  }

  async updatePosition(data, token) {
    return this.post(CreatePositionApiUrls.updatePosition, data, token);
  }

  async deletePosition(data, token) {
    return this.get(CreatePositionApiUrls.deletePosition, data, token);
  }

  async getPositionsByCompanyId(data, token) {
    return this.get(CreatePositionApiUrls.getPositionsByCompanyId, data, token);
  }

  async get(url, data, token, eAlert, sAlert) {
    return await this.httpsClient.get(url, data, token, eAlert, sAlert);
  }

  async post(url, data, token, eAlert, sAlert) {
    this.cache.remove("tests");

    return await this.httpsClient.post(url, data, token, eAlert, sAlert);
  }
}
