import React, { useEffect, useState } from "react";
import validator from "validator";
import { useLocation, useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";

import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";

import { login } from "../../Store/Auth/AuthThunk";

import ApiHelper from "../../Helpers/ApiHelper";
import UseAuthHelper from "../../Helpers/Auth/UseAuthHelper";
import IconSvg from "../../App/AppMenu/IconSvg";
import Overlay from "../../SharedComponents/Overlay";
import { useTranslation } from "react-i18next";
import { SetCompanyUser } from "./Store";

const PWD_Regex = /^(?=.*[a-z])(?=.*[^a-zA-Z\d\s:])(?=.*[A-Z])(?=.*[0-9]).{6,24}$/;

export function Login() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { setAuth } = UseAuthHelper();
  useEffect(() => {
    document.getElementById("login")?.remove();
  }, []);

  const [showOverlay, setShowOverlay] = useState(false);

  const [postData, setPostData] = useState({
    email: "",
    password: "",
  });

  const [validation, setValidation] = useState({
    mail: null,
    password: null,
  });

  const [modal, setModalShow] = useState({
    show: false,
  });

  const [rememberMe, setRememberMe] = useState(false);

  const [forgotPasswordModal, setForgotPasswordModal] = useState(false);

  let SendNewPassword = () => {
    setShowOverlay(true);
    ApiHelper.getAsync("user/passwordresend", { mail: postData.email }, function (result) {
      setShowOverlay(false);
      setForgotPasswordModal(false);

      if (result.status !== 200) {
        //todo : apihelperdan donen istekleri statuslerine gore handle et
        setModalShow(() => ({
          show: true,
          text: result.status,
          heading: "Hata",
        }));
      } else if (result && result.data.statusCode) {
        setModalShow(() => ({
          show: true,
          text: result.data.data,
          heading: "Başarılı",
        }));
      } else if (result && !result.data.statusCode) {
        setModalShow(() => ({
          show: true,
          text: result.data.data,
          heading: "Hata",
        }));
      }
    });
  };

  let onSubmit = () => {
    dispatch(login(postData));

    try {
      setShowOverlay(true);
      ApiHelper.postAsync("user/login", postData, function (result) {
        setShowOverlay(false);
        if (result.status !== 200) {
          //todo : apihelperdan donen istekleri statuslerine gore handle et
          setModalShow(() => ({
            show: true,
            text: result.status,
            heading: "Hata",
          }));
        } else if (result && result.data.statusCode) {
          let data = result.data.data;
          // let jwtToken = data.jwtToken;
          //roles ekle

          setAuth({ data });
          if (rememberMe) localStorage.setItem("cpdata", JSON.stringify(data));
          if (data.userRole === "SuperAdmin") {
            navigate("/sdash");
          } else if (data.userRole === "Admin") {
            dispatch(SetCompanyUser({ companyUser: data.companyUser }));
            // TODO companyUser redux a set et
            if (state?.wrapUpId) {
              navigate("/wrapup/" + state?.wrapUpId);
            } else navigate("/dash");
          } else {
            navigate("/dash");
          }
        } else if (result && !result.data.statusCode) {
          setModalShow(() => ({
            show: true,
            text: result.data.data,
            heading: "Hata",
          }));
        }
      });
    } catch (err) {
      setShowOverlay(false);
    }
  };

  let checkValidationAndSet = (value, inputType) => {
    if (inputType === "mail") {
      setPostData((postData) => ({ ...postData, email: value }));
      if (!validator.isEmail(value)) {
        setValidation((validation) => ({ ...validation, mail: true }));
      } else {
        setValidation((validation) => ({ ...validation, mail: false }));
      }
    } else if (inputType === "password") {
      setPostData((postData) => ({ ...postData, password: value }));

      if (value === "" || !PWD_Regex.test(value)) {
        setValidation((validation) => ({ ...validation, password: true }));
      } else {
        setValidation((validation) => ({ ...validation, password: false }));
      }
    }
  };

  let modalClose = () => {
    if (modal.succes) navigate("/login");
    else setModalShow(false);
  };

  let logoHeightWidth = window.innerWidth <= 500 ? "100px" : "200px";

  return (
    <Container className="login-page-container">
      {showOverlay && <Overlay text="Bilgiler kontrol ediliyor" />}
      <Row className="justify-content-center">
        <Col xs="12" md="5">
          <div className="login-page-logo-container">
            <IconSvg icon="cp-logo" height={logoHeightWidth} width={logoHeightWidth} />
          </div>
          <h1 className="login-page-welcome-header">{t("welcome")}</h1>
          <h5 className="mt-3 login-page-info-h5">{t("enterYourUsernameAndPasswordToLogin")}</h5>
          <h6 className="mt-2">{t("email")}</h6>
          <Form.Group className="mb-3">
            <Form.Control
              placeholder={t("yourEmail")}
              type="email"
              onChange={(e) => checkValidationAndSet(e.target.value, "mail")}
              isInvalid={validation.mail}
              value={postData.email}
            />
          </Form.Group>
          <h6>{t("password")}</h6>
          <Form.Group>
            <Form.Control
              type="password"
              placeholder={t("yourPassword")}
              onChange={(e) => checkValidationAndSet(e.target.value, "password")}
              isInvalid={validation.password}
            />
          </Form.Group>
          <br />
          <div className="login-page-logo-container">
            <Form>
              <Form.Check // prettier-ignore
                type="switch"
                id="custom-switch"
                label={t("rememberMe")}
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
              />
            </Form>
          </div>

          <Row className="justify-content-center mt-3">
            <Col xs="12" md="6">
              <Button
                style={{ width: "100%", height: "50px" }}
                className={"mt-4 mb-4"}
                variant="primary"
                onClick={onSubmit}
                disabled={!(validation.mail === false && validation.password === false)}
              >
                {t("login")}
              </Button>

              <div className="login-page-logo-container">
                <p className="forgot-password-button" onClick={() => setForgotPasswordModal(true)}>
                  {t("iForgotMyPassword")}
                </p>
              </div>
              <div className="login-page-logo-container">
                <p className="forgot-password-button" onClick={() => navigate("/register")}>
                  {t("register")}
                </p>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <Modal show={forgotPasswordModal} onHide={() => setForgotPasswordModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>{t("sendNewPassword")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            placeholder={t("yourEmail")}
            type="email"
            onChange={(e) => checkValidationAndSet(e.target.value, "mail")}
            isInvalid={validation.mail}
            value={postData.email}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={SendNewPassword} disabled={validation.mail !== false}>
            {t("send")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={modal.show} onHide={modalClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{modal.heading}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modal.text}</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={modalClose}>
            {t("close")}
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}
