import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { createCandidate_, deleteCandidate, updateCandidate } from "../Store/Thunk";
import { ShowUpsertCandidateModal, UpdateUpsertCandidateModalData } from "../Store";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const UpsertCandidateModal = ({ positionList }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.adminData.companyUser.company.companyId);
  const candidate = useSelector((state) => state.createCandidate.candidate);
  const isUpdateOperation = useSelector((state) => state.createCandidate.isUpdateOperation);
  const isShown = useSelector((state) => state.createCandidate.upsertCandidateModalIsShown);

  const [candidateName, setCandidateName] = useState("");
  const [candidateEmail, setCandidateEmail] = useState("");
  const [candidatePhoneNumber, setCandidatePhoneNumber] = useState("");
  const [positionId, setPositionId] = useState(0);
  const [candidateId, setCandidateId] = useState("");

  useEffect(() => {
    if (candidate) {
      setCandidateId(candidate?.candidateId);
      setCandidateName(candidate?.candidateName);
      setCandidateEmail(candidate?.email);
      setCandidatePhoneNumber(candidate?.phoneNumber);
      setPositionId(candidate?.positionId);
    }
  }, [candidate?.email, candidate?.name, candidate?.phoneNumber, candidate?.positionId, candidate?.candidateId]);

  const ClearUpsertCandidateModalData = () => {
    dispatch(UpdateUpsertCandidateModalData({}));
    setCandidateId("");
    setCandidateName("");
    setCandidateEmail("");
    setCandidatePhoneNumber("");
    setPositionId(0);
    dispatch(ShowUpsertCandidateModal({ upsertCandidateModalIsShown: false }));
  };

  const DeleteCandidate = (id) => {
    dispatch(deleteCandidate({ id: id }));
    ClearUpsertCandidateModalData();
  };

  const HandleSubmit = (e) => {
    e.preventDefault();
    if (isUpdateOperation) {
      dispatch(
        updateCandidate({
          name: candidateName,
          positionId: positionId,
          email: candidateEmail,
          phoneNumber: candidatePhoneNumber,
          candidateId: candidateId,
        })
      );
    } else {
      dispatch(
        createCandidate_({
          name: candidateName,
          positionId: positionId,
          email: candidateEmail,
          phoneNumber: candidatePhoneNumber,
          companyId: companyId,
        })
      );
    }
    ClearUpsertCandidateModalData();
  };

  return (
    <Modal
      size={"lg"}
      dialogClassName={"custom-modal-dialog"}
      contentClassName={"custom-modal-content"}
      show={isShown}
      onHide={() => {
        ClearUpsertCandidateModalData();
      }}
    >
      <div className={"bordered"}>
        <Form onSubmit={HandleSubmit}>
          <Modal.Header closeButton className={"p-0 border-bottom-0"}></Modal.Header>
          <Modal.Body>
            <Form.Label column={"sm"} className={"text-20 text-color-gray-light fw-medium"}>
              Aday adı soyadı
            </Form.Label>
            <Form.Control
              className={"default-text-input"}
              style={{ height: "50px" }}
              onChange={(e) => setCandidateName(e.target.value)}
              value={candidateName && candidateName}
              placeholder={t("enterCandidateNamePlaceholder")}
            ></Form.Control>

            <Row>
              <Col>
                <Form.Label column={"sm"} className={"text-20 text-color-gray-light fw-medium"}>
                  Telefon Numarası
                </Form.Label>
                <Form.Control
                  className={"default-text-input"}
                  style={{ height: "50px" }}
                  type={"number"}
                  onChange={(e) => setCandidatePhoneNumber(e.target.value)}
                  value={candidatePhoneNumber && Number(candidatePhoneNumber)}
                  placeholder={t("enterCandidatePhoneNumberPlaceholder")}
                ></Form.Control>
              </Col>
              <Col>
                <Form.Label column={"sm"} className={"text-20 text-color-gray-light fw-medium"}>
                  E-mail
                </Form.Label>
                <Form.Control
                  className={"default-text-input"}
                  style={{ height: "50px" }}
                  onChange={(e) => setCandidateEmail(e.target.value)}
                  value={candidateEmail && candidateEmail}
                  placeholder={t("enterCandidateEmailPlaceholder")}
                ></Form.Control>
              </Col>
            </Row>

            <Form.Label column={"sm"} className={"text-20 text-color-gray-light fw-medium"}>
              Pozisyon
            </Form.Label>

            <Form.Select
              className={"default-text-input"}
              style={{ height: "50px" }}
              value={positionId ? positionId : ""}
              onChange={(e) => {
                setPositionId(e.target.value);
              }}
            >
              <option value={0}>{t("choosePositionDropdownToggleLabel")}</option>
              {positionList.map((position) => {
                return <option value={position.id}>{position.name}</option>;
              })}
            </Form.Select>
          </Modal.Body>
          <Modal.Footer className={"justify-content-evenly"}>
            {isUpdateOperation && (
              <Col xs={"auto"}>
                <Button
                  style={{ height: "50px", width: "200px" }}
                  className={"button secondary"}
                  variant={"danger"}
                  onClick={() => {
                    DeleteCandidate(candidate.candidateId);
                  }}
                >
                  DELETE
                </Button>
              </Col>
            )}
            <Col xs={"auto"}>
              <Button type={"submit"} className={"button primary"} style={{ height: "50px", width: "200px" }}>
                {isUpdateOperation ? "UPDATE" : "CREATE"}
              </Button>
            </Col>
          </Modal.Footer>
        </Form>
      </div>
    </Modal>
  );
};
export default UpsertCandidateModal;
