import { createAsyncThunk } from "@reduxjs/toolkit";
import CandidateAPI, { CandidateApiUrls } from "../API/CandidateAPI";

import LocalStorage from "../../../../Services/Storage/LocalStorage";
import StorageModel from "../../../../Models/StorageModel";
import HttpsClient from "../../../../Services/HttpsClient";
import ApiHelper from "../../../../Services/ApiHelper";
import Cache from "../../../../Services/Cache";

const storage = new LocalStorage();
const storageModel = new StorageModel(storage);
const cache = new Cache(storageModel);

const httpsClient = new HttpsClient();
const apiHelper = new ApiHelper(httpsClient);
const candidateAPI = new CandidateAPI(apiHelper, cache);

export const getCandidateById = createAsyncThunk(
  CandidateApiUrls.getCandidateById,
  async (payload, { getState, dispatch }) => {
    const state = getState();
    const token = state.auth.user.token;
    const response = await candidateAPI.getCandidateById(payload, token);
    await dispatch(getCommentsByCandidateId(payload));
    return response;
  }
);
export const getCommentsByCandidateId = createAsyncThunk(
  CandidateApiUrls.getCommentsByCandidateId,
  async (payload, { getState, dispatch }) => {
    const state = getState();
    const token = state.auth.user.token;

    if (!payload) {
      payload = { id: state.candidate.candidate.candidateId };
    }
    return await candidateAPI.getCommentsByCandidateId(payload, token);
  }
);
export const deleteComment = createAsyncThunk(
  CandidateApiUrls.deleteComment,
  async (payload, { getState, dispatch }) => {
    const state = getState();
    const token = state.auth.user.token;

    const response = await candidateAPI.deleteComment(payload, token);

    dispatch(getCommentsByCandidateId());
    return response;
  }
);

export const upsertComment = createAsyncThunk(
  CandidateApiUrls.upsertComment,
  async (payload, { getState, dispatch }) => {
    const state = getState();
    const token = state.auth.user.token;

    const response = await candidateAPI.upsertComment(payload, token);

    dispatch(getCommentsByCandidateId());
    return response;
  }
);
