import axios from "axios";
import { BaseApiUrl } from "../Helpers/ApiHelper";

export default class HttpClient {
  constructor(baseURL) {
    this.baseURL = BaseApiUrl;
  }

  async makeRequest(method, url, param, data, headers, eAlert, sAlert) {
    try {
      const response = await axios({
        method,
        url: this.baseURL + url,
        data,
        params: param,
        headers,
      });
      const isDebug = window.isDebug || false;

      // Custom message alerts based on response status and debug mode
      if (isDebug || (response.data.statusCode === 0 && eAlert)) {
        alert(
          `BusinessStatus => ${response.data.statusCode}\n` +
            `BusinessMessage => ${response.data.message}\n` +
            `URL => ${url}\n` +
            `HTTP Status => ${response.status}`
        );
      } else if (response.data.statusCode === 1 && sAlert) {
        alert(`${response.data.message}\nURL => ${url}`);
      }

      // Log debug info to the console for additional traceability
      if (isDebug) {
        console.log("Debug Info:", {
          url,
          responseStatus: response.status,
          businessStatus: response.data.statusCode,
          businessMessage: response.data.message,
          responseData: response.data,
        });
      } //TODO SET This TO sAlert //TODO SET This TO sAlert

      return response.data;
    } catch (err) {
      if (window.isDebug) {
        console.log(err);
        alert(err.response?.status + "- Hata --> " + url + " // " + err.name + " - " + err.message);
      }
      if (err.response?.status === 401) {
        localStorage.clear();
        window.location.reload(true);
      }
      throw err;
    }
  }
}
