import { createAsyncThunk } from "@reduxjs/toolkit";
import CreateCandidateAPI, { CreateCandidateApiUrls } from "../API/CreateCandidateAPI";

import LocalStorage from "../../../../Services/Storage/LocalStorage";
import StorageModel from "../../../../Models/StorageModel";
import HttpsClient from "../../../../Services/HttpsClient";
import ApiHelper from "../../../../Services/ApiHelper";
import Cache from "../../../../Services/Cache";

const storage = new LocalStorage();
const storageModel = new StorageModel(storage);
const cache = new Cache(storageModel);

const httpsClient = new HttpsClient();
const apiHelper = new ApiHelper(httpsClient);
const createCandidateAPI = new CreateCandidateAPI(apiHelper, cache);

export const getCandidatesByCompanyId = createAsyncThunk(
  CreateCandidateApiUrls.getCandidatesByCompanyId,
  async (payload, { getState, dispatch }) => {
    const state = getState();
    const token = state.auth.user.token;
    if (!payload) {
      payload = { companyId: state.adminData.companyUser.company.companyId };
    }
    return await createCandidateAPI.getCandidatesByCompanyId(payload, token);
  }
);

export const createCandidate_ = createAsyncThunk(
  CreateCandidateApiUrls.createCandidate,
  async (payload, { getState, dispatch }) => {
    const state = getState();
    const token = state.auth.user.token;

    const response = await createCandidateAPI.createCandidate(payload, token);

    dispatch(getCandidatesByCompanyId());

    return response;
  }
);

export const updateCandidate = createAsyncThunk(
  CreateCandidateApiUrls.updateCandidate,
  async (payload, { getState, dispatch }) => {
    const state = getState();
    const token = state.auth.user.token;

    const response = await createCandidateAPI.updateCandidate(payload, token);

    dispatch(getCandidatesByCompanyId());

    return response;
  }
);

export const deleteCandidate = createAsyncThunk(
  CreateCandidateApiUrls.deleteCandidate,
  async (payload, { getState, dispatch }) => {
    const state = getState();
    const token = state.auth.user.token;

    const response = await createCandidateAPI.deleteCandidate(payload, token);

    dispatch(getCandidatesByCompanyId());

    return response;
  }
);
