import { createAsyncThunk } from "@reduxjs/toolkit";
import ProjectAPI, { ProjectApiUrls } from "../API/ProjectAPI";

import LocalStorage from "../../../../Services/Storage/LocalStorage";
import StorageModel from "../../../../Models/StorageModel";
import HttpsClient from "../../../../Services/HttpsClient";
import ApiHelper from "../../../../Services/ApiHelper";
import Cache from "../../../../Services/Cache";

const storage = new LocalStorage();
const storageModel = new StorageModel(storage);
const cache = new Cache(storageModel);

const httpsClient = new HttpsClient();
const apiHelper = new ApiHelper(httpsClient);
const projectAPI = new ProjectAPI(apiHelper, cache);

export const getProjectsById = createAsyncThunk(
  ProjectApiUrls.getPositionById,
  async (payload, { getState, dispatch }) => {
    const state = getState();
    const token = state.auth.user.token;
    if (!payload) {
      payload = { id: state.projects.assessmentId };
    }
    return await projectAPI.getCandidatesByAssessmentId(payload, token);
  }
);
