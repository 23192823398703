import { createAsyncThunk } from "@reduxjs/toolkit";
import PositionAPI, { CreatePositionApiUrls } from "../API/CreatePositionAPI";

import LocalStorage from "../../../../Services/Storage/LocalStorage";
import StorageModel from "../../../../Models/StorageModel";
import HttpsClient from "../../../../Services/HttpsClient";
import ApiHelper from "../../../../Services/ApiHelper";
import Cache from "../../../../Services/Cache";

const storage = new LocalStorage();
const storageModel = new StorageModel(storage);
const cache = new Cache(storageModel);

const httpsClient = new HttpsClient();
const apiHelper = new ApiHelper(httpsClient);
const positionAPI = new PositionAPI(apiHelper, cache);

export const getPositionsByCompanyId = createAsyncThunk(
  CreatePositionApiUrls.getPositionById,
  async (payload, { getState, dispatch }) => {
    const state = getState();
    const token = state.auth.user.token;
    if (!payload) {
      payload = { id: state.adminData.companyUser.company.companyId };
    }
    return await positionAPI.getPositionsByCompanyId(payload, token);
  }
);

export const createPosition_ = createAsyncThunk(
  CreatePositionApiUrls.createPosition,
  async (payload, { getState, dispatch }) => {
    const state = getState();
    const token = state.auth.user.token;

    const response = await positionAPI.createPosition(payload, token);

    dispatch(getPositionsByCompanyId());

    return response;
  }
);

export const updatePosition = createAsyncThunk(
  CreatePositionApiUrls.updatePosition,
  async (payload, { getState, dispatch }) => {
    const state = getState();
    const token = state.auth.user.token;

    const response = await positionAPI.updatePosition(payload, token);

    dispatch(getPositionsByCompanyId());

    return response;
  }
);

export const deletePosition = createAsyncThunk(
  CreatePositionApiUrls.deletePosition,
  async (payload, { getState, dispatch }) => {
    const state = getState();
    const token = state.auth.user.token;

    const response = await positionAPI.deletePosition(payload, token);

    dispatch(getPositionsByCompanyId());

    return response;
  }
);
