import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ShowUpsertPositionModal, UpdateUpsertPositionModalData } from "../Store";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { createPosition_, deletePosition, updatePosition } from "../Store/Thunk";
import Col from "react-bootstrap/Col";

const UpsertPositionModal = ({ companyId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const companyUserId = useSelector((state) => state.adminData.companyUser.companyUserId);
  const position = useSelector((state) => state.createPosition.upsertPositionModalData);
  const isUpdateOperation = useSelector((state) => state.createPosition.isUpdateOperation);
  const isShown = useSelector((state) => state.createPosition.upsertPositionModalIsShown);

  const [positionName, setPositionName] = useState("");
  const [positionDescription, setPositionDescription] = useState("");

  useEffect(() => {
    setPositionName(position.name);
    setPositionDescription(position.description);
  }, [position.name, position.description]);

  const ClearUpsertPositionModalData = () => {
    dispatch(UpdateUpsertPositionModalData({ position: {} }));
    dispatch(ShowUpsertPositionModal({ upsertPositionModalIsShown: false }));
  };

  const DeletePosition = (id) => {
    dispatch(deletePosition({ id: id }));
    ClearUpsertPositionModalData();
  };

  const HandleSubmit = (e) => {
    e.preventDefault();
    if (isUpdateOperation) {
      dispatch(
        updatePosition({
          positionId: position.id,
          name: positionName,
          description: positionDescription,
        })
      );
    } else {
      dispatch(
        createPosition_({
          name: positionName,
          companyId: companyId,
          companyUserId: companyUserId,
          description: positionDescription,
        })
      );
    }
    dispatch(ShowUpsertPositionModal({ upsertPositionModalIsShown: false }));
  };

  return (
    <Modal
      size={"lg"}
      show={isShown}
      onHide={() => {
        ClearUpsertPositionModalData();
      }}
      dialogClassName={"custom-modal-dialog"}
      contentClassName={"custom-modal-content"}
    >
      <div className={"bordered"}>
        <Form onSubmit={HandleSubmit}>
          <Modal.Header closeButton className={"p-0 border-bottom-0 justify-content-center"}>
            {isUpdateOperation ? "UPDATE" : "CREATE"}
          </Modal.Header>
          <Modal.Body>
            <Form.Label column={"sm"} className={"text-20 text-color-gray-light fw-medium"}>
              Position adı
            </Form.Label>
            <Form.Control
              className={"default-text-input"}
              style={{ height: "50px" }}
              onChange={(e) => setPositionName(e.target.value)}
              value={positionName && positionName}
              placeholder={t("enterPositionNamePlaceholder")}
            ></Form.Control>

            <Form.Label column={"sm"} className={"text-20 text-color-gray-light fw-medium"}>
              Position açıklaması
            </Form.Label>
            <Form.Control
              as={"textarea"}
              className={"default-text-input"}
              style={{ height: "150px" }}
              onChange={(e) => setPositionDescription(e.target.value)}
              value={positionDescription && positionDescription}
              placeholder={t("enterPositionDescriptionPlaceholder")}
            ></Form.Control>
          </Modal.Body>
          <Modal.Footer className={"justify-content-evenly border-bottom-0"}>
            {isUpdateOperation && (
              <Col xs={"auto"}>
                <Button
                  variant={"danger"}
                  className={"button secondary"}
                  style={{ height: "50px", width: "200px" }}
                  onClick={() => {
                    DeletePosition(position.id);
                  }}
                >
                  DELETE
                </Button>
              </Col>
            )}
            <Col xs={"auto"}>
              <Button type={"submit"} className={"button primary"} style={{ height: "50px", width: "200px" }}>
                {isUpdateOperation ? "UPDATE" : "CREATE"}
              </Button>
            </Col>
          </Modal.Footer>
        </Form>
      </div>
    </Modal>
  );
};
export default UpsertPositionModal;
