import React from "react";
import IconSvg from "./IconSvg";
import { useTranslation } from "react-i18next";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function MenuButton(props) {
  const { t } = useTranslation();
  const buttonClass = " text-color-gray-dark";
  const selectedClass = " selected text-color-accent";

  return (
    <Row
      className={"side-bar-button show-cursor text-20 " + (props.selected === props.page ? selectedClass : buttonClass)}
      onClick={() => props.HandleSideMenuButtonClick(props.pageObj)}
    >
      <Col xs={12} xl={"auto"} className={"align-content-center"}>
        <Row className={"justify-content-center side-bar-menu-icon"}>
          <IconSvg page={props.page} isSelected={props.selected === props.page} />
        </Row>
      </Col>
      <Col xs={12} xl={"auto"} className={"align-content-center d-none d-xl-block p-0"}>
        {t(props.page)}
      </Col>
    </Row>
  );
}
