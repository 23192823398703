import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import IconSvg from "../../../../App/AppMenu/IconSvg";
import { deleteComment } from "../Store/Thunk";
import { SetSelectedComment } from "../Store";
import { ParseDate } from "../../../../Helpers/DateHelper";

const CommentList = () => {
  const comments = useSelector((state) => state.candidate.comments);
  const dispatch = useDispatch();
  const HandleDeleteComment = (commentId) => {
    debugger;
    dispatch(deleteComment({ id: commentId }));
  };

  return (
    <Card className={"default-card no-shadow py-3 px-4"}>
      {comments.map((comment, index) => {
        return (
          <div className={"border-bottom mb-2 pb-2"} style={{ borderColor: "#E7ECEF" }}>
            <Row className={"justify-content-between"}>
              <Col xs={"auto"}>
                <Row>
                  <Col xs={"auto"} className={"align-content-center"}>
                    <IconSvg icon={"comment"}></IconSvg>
                  </Col>
                  <Col xs={"auto"} className={"px-0 align-content-center text-18 text-color-gray-light fw-medium"}>
                    {comment.companyUserName}
                  </Col>
                </Row>
              </Col>
              <Col xs={"auto"}>
                <Row>
                  <Col xs={"auto"}>
                    {ParseDate(comment.createdAt).date + " "}
                    {ParseDate(comment.createdAt).time}
                  </Col>
                  <Col xs={"auto"}>
                    <div className={"show-cursor"} onClick={() => dispatch(SetSelectedComment(comment))}>
                      <IconSvg icon={"edit"}></IconSvg>
                    </div>
                  </Col>
                  <Col xs={"auto"}>
                    <div className={"show-cursor"} onClick={() => HandleDeleteComment(comment.commentId)}>
                      <IconSvg icon={"delete"}></IconSvg>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col className={"text-16 text-color-gray-dark fw-medium"}>{comment.content}</Col>
            </Row>
          </div>
        );
      })}
    </Card>
  );
};
export default CommentList;
