import { configureStore } from "@reduxjs/toolkit";
import BasicGraphSummary from "../Features/CreateApplication/ReportItems/BasicGraphSummary/Store";
import BasicGraphDetail from "../Features/CreateApplication/ReportItems/BasicGraphDetail/Store";
import MonoTexruary from "../Features/CreateApplication/ReportItems/MonoTextuary/Store";
import DualTextuary from "../Features/CreateApplication/ReportItems/DualTextuary/Store";
import ReportItemBase from "../Features/CreateApplication/ReportItems/ReportItemBase/Store";
import Auth from "./Auth/index";
import ApplicationResults from "../Features/ApplicationResults/Store";
import ExportExcelAllFilter from "../Features/ExportExcelAllFilter/Store";
import Inventory from "../Features/CreateApplication/Inventory/Store";
import TestEntityList from "../Features/CreateApplication/TestEntityList/Store";
import Test from "../Features/CreateApplication/Test/Store";
import Scope from "../Features/CreateApplication/Scope/Store";
import Scale from "../Features/CreateApplication/ScoringScale/Store";
import ReportItem from "../Features/CreateApplication/ReportItems/ReportItem/Store";
import ReportScheme from "../Features/CreateApplication/ReportScheme/Store";
import PdfDownloadButton from "../Features/PdfDownloadButton/Store";
import WrapUp from "../Features/WrapUp/Store";
import Assessment from "../Features/CreateApplication/Assessment/Store";
import Survey from "../Features/CreateApplication/Survey/Store";
import Company from "../Features/CreateCompany/Company/Store";
import CompanyUser from "../Features/CreateCompany/CompanyUser/Store";
import Credits from "../Features/CreateCompany/Credits/Store";
import AdminData from "../Components/LoginRegister/Store";
import CreatePosition from "../Features/AdminPages/CreatePosition/Store";
import Position from "../Features/AdminPages/Position/Store";
import CreateCandidate from "../Features/AdminPages/CreateCandidate/Store";
import Candidate from "../Features/AdminPages/Candidate/Store";
import Project from "../Features/AdminPages/Project/Store";
import CompanyInfo from "../Features/CreateCompany/CompanyInfo/Store";
import AdminDash from "../Features/AdminPages/Dashboard/Store";
//TODO: separate admin redux from superAdmin redux
export default configureStore({
  reducer: {
    auth: Auth,
    applicationResults: ApplicationResults,
    exportExcelAllFilter: ExportExcelAllFilter,
    inventory: Inventory,
    testEntityList: TestEntityList,
    test: Test,
    scope: Scope,
    scale: Scale,
    basicGraphSummary: BasicGraphSummary,
    basicGraphDetail: BasicGraphDetail,
    monoTextuary: MonoTexruary,
    dualTextuary: DualTextuary,
    reportItemBase: ReportItemBase,
    reportItem: ReportItem,
    reportScheme: ReportScheme,
    pdfDownloadButton: PdfDownloadButton,
    wrapUp: WrapUp,
    assessment: Assessment,
    survey: Survey,
    company: Company,
    companyUser: CompanyUser,
    credits: Credits,
    createPosition: CreatePosition,
    adminData: AdminData,
    position: Position,
    createCandidate: CreateCandidate,
    projects: Project,
    adminDash: AdminDash,
    companyInfo: CompanyInfo,
    candidate: Candidate,
  },
});
