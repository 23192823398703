import React, { useEffect, useState } from "react";

import TopNavigation from "./TopNavigation";
import MenuButton from "./MenuButton";
import UseAuthHelper from "../../Helpers/Auth/UseAuthHelper";
import { useSelector } from "react-redux";
import ApiHelper from "../../Helpers/ApiHelper";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import IconSvg from "./IconSvg";
import Dropdown from "react-bootstrap/Dropdown";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
// TODO: translate these to english
export const PAGES = {
  0: ["Dashboard", "Admin", "/dash"], //1: ["Projects", "Admin", "/projects"],
  //2: ["Results", "Admin", "/results"],
  //3: ["Settings", "Admin", "/settings"],
  4: ["Uygulamalar", "User", "/userdash"],
  5: ["Results", "User", "/myresults"],
  6: ["Dashboard", "SuperAdmin", "/sdash"],
  7: ["Assessment", "SuperAdmin", "/assessment/"],
  8: ["Inventory", "SuperAdmin", "/inventory"],
  9: ["Test", "SuperAdmin", "/test"],
  10: ["Survey", "SuperAdmin", "/Survey/"],
  11: ["Results", "SuperAdmin", "/ApplicationResults/"],
  12: ["Company", "SuperAdmin", "/Company/"],
  13: ["Position", "Admin", "/createPosition/"],
  14: ["Project", "Admin", "/project/"],
  15: ["Candidate", "Admin", "/createCandidate/"],
};
export default function AppMenu(props) {
  const { t } = useTranslation();
  const { auth, setAuth } = UseAuthHelper();
  const user = auth?.data;
  const companyName = useSelector((state) => state.adminData?.companyUser?.company?.companyName);
  const companyLogo = useSelector((state) => state.adminData?.companyUser?.company?.logoPath);
  const companyIcon = useSelector((state) => state.adminData?.companyUser?.company?.iconPath);
  let landingPage = "";

  if (user.userRole === "User") landingPage = "Uygulamalar";
  if (user.userRole === "Admin") landingPage = "Anasayfa";
  if (user.userRole === "SuperAdmin") landingPage = "Anasayfa";
  const [selected, setSelected] = useState(landingPage);

  let dropdownIconSize = window.innerWidth <= 500 ? "20" : "30";

  const pages = Object.values(PAGES);

  useEffect(() => {
    pages.forEach((page) => {
      if (window.location.hash.substring(1) === page[2]) setSelected(page[0]);
    });
  }, []);

  let HandleLogOut = () => {
    localStorage.clear();
    setAuth(null);
    window.location.href = window.location.origin;
  };

  let handleLanguageChange = (lang) => {
    i18next.changeLanguage(lang);
  };

  const HandleSideMenuButtonClick = (page) => {
    setSelected(page[0]);
    window.location.href = window.location.origin + "/#" + page[2];
  };

  return (
    <>
      <div className="top-bar">
        <TopNavigation pageName={selected} userName={props.userName} />
      </div>

      <div className="side-bar d-flex flex-column">
        <div className={"side-bar-menu"}>
          <div className={"side-bar-company-icon justify-content-center"}>
            {companyIcon && (
              <img
                height="50"
                alt={"Company Icon"}
                src={ApiHelper.baseURL + "wwwroot/logo/" + companyIcon}
                width="50"
              />
            )}
          </div>
          <div className={"side-bar-company-logo  justify-content-center"}>
            {companyLogo && (
              <img
                height="60"
                alt={"Company Logo"}
                src={ApiHelper.baseURL + "wwwroot/logo/" + companyLogo}
                width="200"
              />
            )}
          </div>
          <div className={"text-14 fw-medium text-color-gray-light mb-3 side-bar-non-menu-content hide-if-under-1200"}>
            <Row>
              <Col xs={"auto"}>ANA MENÜ</Col>
            </Row>
          </div>

          <div>
            {pages.map((page, i) => {
              if (page[1] === props.role)
                return (
                  <MenuButton
                    key={i + "page"}
                    page={page[0]}
                    pageObj={page}
                    selected={selected}
                    HandleSideMenuButtonClick={HandleSideMenuButtonClick}
                  />
                );
            })}
          </div>
        </div>
        <div className={"mt-auto mb-5 text-20 text-color-gray-light fw-medium side-bar-non-menu-content"}>
          <Dropdown drop={"up-centered"}>
            <Dropdown.Toggle as={"div"} className={"dropdown-toggle no-caret"}>
              <Row className={"hover-bg-darken mb-4"}>
                <Col xs={"auto"}>
                  <IconSvg icon={"settings"}></IconSvg>
                </Col>
                <Col xs={"auto"} className={"ps-0 hide-if-under-1200"}>
                  <div>Ayarlar</div>
                </Col>
              </Row>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item style={{ backgroundColor: i18next.language === "tr" ? "#dcdcdc" : "" }}>
                <Row onClick={() => handleLanguageChange("tr")}>
                  <Col>{t("turkish")}</Col>
                  <Col xs={3}>
                    <IconSvg icon="flag-tr" height={24} width={24} />
                  </Col>
                </Row>
              </Dropdown.Item>

              <Dropdown.Item style={{ backgroundColor: i18next.language === "en" ? "#dcdcdc" : "" }}>
                <Row onClick={() => handleLanguageChange("en")}>
                  <Col>{t("english")}</Col>
                  <Col xs={3}>
                    <IconSvg icon="flag-uk" height={24} width={24} />
                  </Col>
                </Row>
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item>
                <Row onClick={HandleLogOut}>
                  <Col>{t("logout")}</Col>
                  <Col xs={3}>
                    <IconSvg icon="on-off" height={24} width={24} />
                  </Col>
                </Row>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Row className={"hover-bg-darken"} onClick={HandleLogOut}>
            <Col xs={"auto"}>
              <IconSvg icon={"exit"}></IconSvg>
            </Col>
            <Col xs={"auto"} className={"ps-0 hide-if-under-1200"}>
              <div>Çıkış</div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
