import { createAsyncThunk } from "@reduxjs/toolkit";
import CompanyAPI, { CompanyApiUrls } from "../API/CompanyAPI";

import LocalStorage from "../../../../Services/Storage/LocalStorage";
import StorageModel from "../../../../Models/StorageModel";
import HttpsClient from "../../../../Services/HttpsClient";
import ApiHelper from "../../../../Services/ApiHelper";
import Cache from "../../../../Services/Cache";

const storage = new LocalStorage();
const storageModel = new StorageModel(storage);
const cache = new Cache(storageModel);

const httpsClient = new HttpsClient();
const apiHelper = new ApiHelper(httpsClient);
const companiesAPI = new CompanyAPI(apiHelper, cache);

export const getAllCompanies = createAsyncThunk(
  CompanyApiUrls.getAllCompanies,
  async (payload, { getState, dispatch }) => {
    const state = getState();
    const token = state.auth.user.token;

    return await companiesAPI.getAllCompanies(payload, token);
  }
);

export const createCompany = createAsyncThunk(CompanyApiUrls.createCompany, async (payload, { getState, dispatch }) => {
  const state = getState();
  const token = state.auth.user.token;

  await companiesAPI.createCompany(payload, token);

  dispatch(getAllCompanies());

  return;
});

export const updateCompany = createAsyncThunk(CompanyApiUrls.updateCompany, async (payload, { getState, dispatch }) => {
  const state = getState();
  const token = state.auth.user.token;

  await companiesAPI.updateCompany(payload, token);

  dispatch(getAllCompanies());

  return;
});

export const deleteCompany = createAsyncThunk(CompanyApiUrls.deleteCompany, async (payload, { getState, dispatch }) => {
  const state = getState();
  const token = state.auth.user.token;

  const response = await companiesAPI.deleteCompany(payload, token);

  dispatch(getAllCompanies());

  return;
});
