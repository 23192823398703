import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App/App";
import Store from "./Store";
import "./i18n";
import { ThemeProvider } from "react-bootstrap";
import "./Scss/main.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={Store}>
    <ThemeProvider breakpoints={["xxxl", "xxl", "xl", "lg", "md", "sm", "xs"]} minBreakpoint="xs">
      <App />
    </ThemeProvider>
  </Provider>
);
