import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import BreadCrumb from "../../../Dashboard/BreadCrumb";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import Collapse from "react-bootstrap/Collapse";

import ApiHelper from "../../../../Helpers/ApiHelper";
import UseAuthHelper from "../../../../Helpers/Auth/UseAuthHelper";

const MySwal = withReactContent(Swal);
const xlsx = require("xlsx");

export function CheckBox() {
  const { state } = useLocation();
  const { auth } = UseAuthHelper();
  const user = auth?.data;
  const [open, setOpen] = useState(false);

  const [ScopeQuestions, setScopeQuestions] = useState();
  const [checkboxInput, setCheckboxInput] = useState();
  const [adjList, setAdjList] = useState([]);

  const isSub = state.isSub;

  useEffect(() => {
    if (!ScopeQuestions) RefreshScopeQuestions();
  }, []);

  let AddToAjList = (checkboxInput) => {
    if (!adjList.find((e) => e.QuestionText === checkboxInput)) {
      setAdjList((a) => [...a, { QuestionText: checkboxInput }]);
      setCheckboxInput("");
    } else MySwal.fire(<p>Daha önce eklenmiş</p>);
  };

  let RemoveFromAdjList = (a) => {
    setAdjList(adjList.filter((item) => item.QuestionText !== a));
  };
  let CreateScopeQuestion = () => {
    let postObj = {
      checkboxDtos: adjList,
      scopeId: state.scope.id,
      subScopeId: state.subScope?.id,
    };

    const url = isSub ? "question/CreateSubcheckbox" : "question/CreateCheckBox";

    ApiHelper.postAsync(
      url,
      postObj,
      function () {
        RefreshScopeQuestions();
        setAdjList([]);
        setCheckboxInput("");
      },
      user.token
    );
  };

  let RefreshScopeQuestions = () => {
    const url = isSub ? "question/GetSubCheckboxes" : "question/GetCheckboxes";
    const id = isSub ? state.subScope?.id : state.scope?.id;
    ApiHelper.getAsync(
      url,
      { id },
      function (result) {
        setScopeQuestions(result.data.data);
      },
      user.token
    );
  };

  let DeleteScopeQuestion = (id) => {
    ApiHelper.getAsync(
      "question/DeleteQuestion",
      { id: id },
      function () {
        RefreshScopeQuestions();
      },
      user.token
    );
  };

  const readUploadFile = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet);
        json.forEach((q) => {
          setAdjList((a) => [...a, { QuestionText: q.sorular }]);
        });
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };

  return (
    <div>
      <BreadCrumb
        pageName="Onay Kutusu"
        backPageName={isSub ? "Boyutlar" : "Alt Boyutlar"}
        backUrl={isSub ? "/subscope" : "/scope"}
        backState={
          isSub ? { state: { scope: state.scope, application: state.application } } : { state: state.application }
        }
      >
        <Row>
          <Col>
            <h4>Envanter : {state.application.name}</h4>
          </Col>
          <Col>
            <h4>Boyut : {state.scope.name}</h4>
          </Col>
          {isSub && (
            <Col>
              <h4>Alt Boyut : {state.subScope?.name}</h4>
            </Col>
          )}
        </Row>
      </BreadCrumb>

      <Card body className="mb-3">
        <Row className="mb-3">
          <Col xs={12} md={6}>
            <InputGroup>
              <Form.Control
                value={checkboxInput}
                placeholder="Onay Kutusu giriniz"
                onChange={(e) => {
                  setCheckboxInput(e.target.value);
                }}
              />
            </InputGroup>
          </Col>
          <Col xs={12} md={2}>
            <form>
              <input type="file" name="upload" id="upload" onChange={readUploadFile} />
            </form>
          </Col>
          <Col xs={12} md={2}>
            <Button
              style={{ height: "100%", width: "100%" }}
              variant="primary"
              onClick={() => {
                AddToAjList(checkboxInput);
              }}
              disabled={!checkboxInput}
            >
              Ekle
            </Button>
          </Col>
          <Col xs="12" md="2">
            <Button
              style={{ height: "100%", width: "100%" }}
              variant="primary"
              onClick={CreateScopeQuestion}
              disabled={adjList.length > 0 ? false : true}
            >
              Oluştur
            </Button>
          </Col>
        </Row>
        {adjList.length > 0 ? (
          <Row className="justify-content-md-center mb-3">
            <Col
              md="auto"
              onClick={() => setOpen(!open)}
              aria-controls="example-collapse-text"
              aria-expanded={open}
              style={{ cursor: "pointer" }}
            >
              <Button variant="warning"> {!open ? "gizle" : "goster"} </Button>
            </Col>
          </Row>
        ) : null}

        <Collapse in={!open}>
          <ListGroup id="example-collapse-text">
            {adjList &&
              adjList.length > 0 &&
              adjList.map((item, i) => {
                return (
                  <ListGroup.Item key={i} variant={i % 2 ? "secondary" : "primary"} style={{ height: "38px" }}>
                    {item.QuestionText}
                    <p
                      onClick={() => {
                        RemoveFromAdjList(item.QuestionText);
                      }}
                      style={{ float: "right", cursor: "pointer" }}
                    >
                      X
                    </p>
                  </ListGroup.Item>
                );
              })}
          </ListGroup>
        </Collapse>
      </Card>

      {ScopeQuestions?.length > 0 &&
        ScopeQuestions.map((sq, i) => {
          return (
            <Card key={i} className="mb-3">
              <Card.Body>
                <Row>
                  <Col xs="12" md="1">
                    {sq.id}
                  </Col>
                  <Col xs="12" md="9">
                    {sq.checkbox?.questionText}
                  </Col>
                  <Col xs="12" md="1">
                    {sq.order}
                  </Col>
                  <Col xs={12} md={1}>
                    <Button
                      style={{ float: "right" }}
                      size="sm"
                      variant="danger"
                      onClick={() => DeleteScopeQuestion(sq.id)}
                    >
                      Sil
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          );
        })}
    </div>
  );
}
