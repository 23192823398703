import { createSlice } from "@reduxjs/toolkit";
import { getCompanyCredits } from "./Thunk";

export const adminDash = createSlice({
  name: "adminDash",
  initialState: {
    id: "",
    name: "",
    companyCredits: undefined,
    isLoading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCompanyCredits.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getCompanyCredits.fulfilled, (state, action) => {
      state.isLoading = false;
      state.companyCredits = action.payload?.credits;
    });
  },
});

export const { UpdateUpsertPositionModalData, UpdateCompanyId, ChangeIsUpdateOperation, ShowUpsertPositionModal } =
  adminDash.actions;

export default adminDash.reducer;
