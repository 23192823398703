import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import ScrollableCard from "../../../../../SharedComponents/ScrollableCard";
import IconSvg from "../../../../../App/AppMenu/IconSvg";

const ApplicationCardContent = ({ companyCredit }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const companyId = useSelector((state) => state.adminData.companyUser.company.companyId);
  const companyCredits = useSelector((state) => state.adminDash.companyCredits);

  return (
    <div>
      <Row className={"justify-content-center"}>
        <IconSvg icon={"packet"} />
      </Row>
      <Row className={"justify-content-center"}>
        <h4> {companyCredit.assessment.assessmentName}</h4>
      </Row>
      <Row>
        <h6>{companyCredit.creditAmount} Kredi</h6>
      </Row>
      <ScrollableCard emptySpaceHeight={25} maximumHeight={145}>
        <Row className={"mx-0"}>
          {companyCredit.assessment.inventories.map((inventory) => {
            return (
              <Card className={"assessment-row"}>
                <Row className={"justify-content-between m-0"}>
                  <Col xs={"auto"}>{inventory.name}</Col> <Col xs={"auto"}> </Col>
                </Row>
              </Card>
            );
          })}
        </Row>
        <Row className={"mx-0"}>
          {companyCredit.assessment.surveys.map((survey) => {
            return (
              <Card className={"assessment-row"}>
                <Row className={"justify-content-between m-0"}>
                  <Col xs={"auto"}>{survey.name}</Col> <Col xs={"auto"}> </Col>
                </Row>
              </Card>
            );
          })}
        </Row>
        <Row className={"mx-0"}>
          {companyCredit.assessment.tests.map((test) => {
            return (
              <Card className={"assessment-row"}>
                <Row className={"justify-content-between m-0"}>
                  <Col xs={"auto"}>{test.name}</Col> <Col xs={"auto"}> </Col>
                </Row>
              </Card>
            );
          })}
        </Row>
      </ScrollableCard>
    </div>
  );
};
export default ApplicationCardContent;
