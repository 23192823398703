import React, { useEffect } from "react";
import { Card } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useLocation, useNavigate } from "react-router-dom";
import IconSvg from "../../../../App/AppMenu/IconSvg";

const PositionCandidatesList = ({ candidatesList }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const companyId = 16;
  const { state } = useLocation();

  useEffect(() => {}, []);

  return (
    <>
      <Card className={"header"}>
        <Row className={"justify-content-between"}>
          <Col xs={4}>
            <Row className={"justify-content-center"}>ad soyad</Row>
          </Col>
          <Col xs={4}>
            <Row>Email</Row>
          </Col>
        </Row>
      </Card>

      {candidatesList &&
        candidatesList.map((candidate, i) => (
          <Card className={"list-row"} key={i}>
            <Row className={"justify-content-between"}>
              <Col xs={4}>
                <Row className={"justify-content-center"}>
                  <Col xs={"auto"} className={"ms-auto"}>
                    <IconSvg icon={"delete"} />
                  </Col>
                  <Col xs={7}>{candidate.name}</Col>
                </Row>
              </Col>
              <Col xs={4}>
                <Row>{candidate.email} cuspro@cuspro.com</Row>
              </Col>
            </Row>
          </Card>
        ))}
    </>
  );
};
export default PositionCandidatesList;
